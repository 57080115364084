import React, { Fragment } from 'react';
import ListaRecursos from './ListaRecursos';

function MapRecursos({
  recursos,
  handleSubmit,
  idObra,
  tiposfaltas,
  tiposperiodos,
  tiposatrasos,
  useWindowDimensions,
  fetchFuncNaoAss,
}) {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {recursos.map((recurso) => (
        <Fragment key={recurso.recursos_id}>
          <ListaRecursos
            recurso={recurso}
            handleSubmit={handleSubmit}
            idObra={idObra}
            tiposfaltas={tiposfaltas}
            tiposperiodos={tiposperiodos}
            tiposatrasos={tiposatrasos}
            useWindowDimensions={useWindowDimensions}
            fetchFuncNaoAss={fetchFuncNaoAss}
          />
        </Fragment>
      ))}
    </div>
  );
}

export default MapRecursos;

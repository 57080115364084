/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable object-shorthand */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';

import { Container, Col, Row, CardBody } from 'reactstrap';
import { Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import yellow from '@mui/material/colors/yellow';
import { FiEdit } from 'react-icons/fi';

import './registarhoras.css';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import FiltrosEscritorio from './components/FiltrosEscritorio';
import BreadListaRegistarHoras from '../../../App/Breadcrumbs/RegistarHoras/Escritorios/BreadListaRegistarHoras';
import FooterLinks from '../../../Account/Footer';
// Get de Recursos
import GetObrasRegistoEscritorio from '../../../../redux/actions/TemposActions/Escritorio/GetAllObrasRegistoEscritorioAction';
import GetEstadosProjeto from '../../../../redux/actions/Global/filters/GetEstadosProjetoAction';
import GetEstadosSubProjeto from '../../../../redux/actions/Global/filters/GetEstadosSubProjetoAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnConsultar: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Tempos() {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  const dispatch = useDispatch();

  const obrasSemRecursos = useSelector((state) => state.tempos.obrasRegistoEscritorio);

  const estadosProjeto = useSelector((state) => state.global.estadosProjeto);
  const estadosSubProjeto = useSelector((state) => state.global.estadosSubProjeto);

  const [estadosProjetoPreSelectedId, setEstadosProjetoPreSelectedId] = useState([
    { id: 2, descricao: 'Aberto' },
  ]);
  const [estadosSubProjetoPreSelectedId, setEstadosSubProjetoPreSelectedId] = useState([
    { id: 0, descricao: '-' },
    { id: 1, descricao: 'Aberto' },
    { id: 2, descricao: 'Iniciado' },
    { id: 3, descricao: 'Executado' },
  ]);

  const fetchObras = () => {
    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);
    return dispatch(GetObrasRegistoEscritorio(eProjetosId, eSubProjetosId));
  };

  const fetchEstadosProjeto = () => {
    dispatch(GetEstadosProjeto());
  };
  const fetchEstadosSubProjeto = () => {
    dispatch(GetEstadosSubProjeto());
  };

  useEffect(() => {
    fetchObras();
  }, []);
  useEffect(() => {
    fetchEstadosProjeto();
  }, []);
  useEffect(() => {
    fetchEstadosSubProjeto();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
      },
    },
    {
      name: 'descricao',
      label: 'Nome da Obra',
      options: {
        filter: false,
      },
    },
    {
      name: 'clienteNome',
      label: 'Cliente',
      options: {
        filter: false,
      },
    },
    // {
    //   name: 'tipoProposta',
    //   label: 'Proposta',
    //   options: {
    //     filter: true,
    //     filterOptions: {
    //       fullWidth: true,
    //     },
    //     sort: true,
    //     empty: true,
    //     setCellProps: () => ({
    //       align: 'left',
    //     }),
    //     customBodyRender: (value, tableMeta) => {
    //       return <div>{tableMeta.rowData[3]}</div>;
    //     },
    //   },
    // },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value) => {
          return (
            <div className="table-btns-registarhoras">
              <Link to={`/app/registarHoras/escritorio/${value}`}>
                <IconButton sx={{ color: '#000' }}>
                  <FiEdit style={{ strokeWidth: '1px' }} />
                </IconButton>
              </Link>
            </div>
          );
        },
      },
    },
  ];

  const tablePage = useSelector((state) => state.global.regHorasEscritorioTablePage);
  const searchTextValue = useSelector((state) => state.global.regHorasEscritorioTableSearch);
  const [searchText, setSearchText] = useState(searchTextValue);

  const options = {
    filter: false,
    filterType: 'dropdown',
    search: false,
    searchText: searchText,
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    page: tablePage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        dispatch({ type: 'REG_HORAS_ESCRITORIO_TABLE_PAGINATION', payload: tableState.page });
      }
      if (action === 'propsUpdate') {
        dispatch({
          type: 'REG_HORAS_ESCRITORIO_TABLE_SEARCH',
          payload: tableState.searchText,
        });
      }
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'Limpar',
      },
    },
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadListaRegistarHoras />
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
              }}
              className="text-underline"
            >
              Registar Horas Escritório
            </h4>
          </Col>
        </Row>
        <FiltrosEscritorio
          estadosProjeto={estadosProjeto}
          estadosSubProjeto={estadosSubProjeto}
          estadosProjetoPreSelectedId={estadosProjetoPreSelectedId}
          estadosSubProjetoPreSelectedId={estadosSubProjetoPreSelectedId}
          setEstadosProjetoPreSelectedId={setEstadosProjetoPreSelectedId}
          setEstadosSubProjetoPreSelectedId={setEstadosSubProjetoPreSelectedId}
          fetchObras={fetchObras}
          classes={classes}
          searchText={searchText}
          setSearchText={setSearchText}
          width={width}
        />
        <CardBody>
          <Row>
            <Col md={12}>
              <Col md={12} sm={12}>
                <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                  <h4
                    style={{
                      width: '100%',
                    }}
                    className="text-underline-title"
                  >
                    Lista de Obras com equipas
                  </h4>
                </Box>
              </Col>
              <Col md={12} sm={12}>
                <MUIDataTable
                  description="Nada para mostrar"
                  data={obrasSemRecursos}
                  columns={columns}
                  options={options}
                />
              </Col>
            </Col>
          </Row>
        </CardBody>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
}

export default Tempos;

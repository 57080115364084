/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardBody, Col } from 'reactstrap';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { useNavigate, Navigate } from 'react-router';
import Notification from 'rc-notification';
import { grey } from '@mui/material/colors';

import { format } from 'date-fns';
import EnviarValidacaoIndividualAD from '../../../../../../redux/actions/ValidacaoActions/validacaoAdmin/enviarValidacaoIndividualAD';
// COMPONENTS
import RoleEncarregado from './roles/RoleEncarregado';
import RoleChefeDepartamento from './roles/RoleChefeDepartamento';
import RoleRecursosHumanos from './roles/RoleRecursosHumanos';
import RoleAdministrador from './roles/RoleAdministrador';

// location

// Notification
// import { FullWideNotification } from '../../../../../../shared/components/Notification';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    '& > *': {
      marginTop: '25px',
      marginLeft: '10px',
      width: '4ch',
    },
  },
  horasBorder: {
    width: '50px',
    height: '40px',
    textAlign: 'center',
    border: '1px solid #000',
    backgroundColor: '#ebebeb',
    borderRadius: '5px',
    padding: '8px',
  },
  btnGuardar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnGuardarLoading: {
    color: 'white',
    backgroundColor: grey[300],
    width: '120px',
    height: '40px',
  },
  buttons: {
    color: '#E21450',
    minWidth: '45px',
    height: '40px',
    marginRight: '8px !important',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function ValidacaoIndividualForm({
  // id,
  // theme,
  infoValIndiv,
  disabledFaltou,
  setDisabledFaltou,
  valueTipoFaltaAutoComplete,
  setValueTipoFaltaAutoComplete,
  // Loading
  loading,
  setLoading,
}) {
  console.log('infoValIndiv', infoValIndiv);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const tiposfaltas = useSelector((state) => state.tiposFaltas.dropdownTiposFaltas);
  const tiposperiodos = useSelector((state) => state.tiposPeriodos.dropdownTiposPeriodos);
  const tiposatrasos = useSelector((state) => state.tiposAtrasos.dropdownTiposAtrasos);
  // ---------- Notification Sytem ------------------- //

  // useEffect(() => {
  //   Notification.newInstance({ style: { top: 65 } }, (n) => {
  //     notificationTC = n;
  //   });
  // }, []);

  // const showNotification = ({ notification, position }, direction) => {
  //   const notificationDefaultProps = {
  //     content: notification(theme),
  //     duration: 5,
  //     closable: true,
  //     className: `${position} ${direction}-support`,
  //   };

  //   notificationTC.notice({
  //     ...notificationDefaultProps,
  //     style: { top: -60, left: 0 },
  //   });
  // };

  // const show = (color, mensagem) =>
  //   showNotification({
  //     notification() {
  //       return <FullWideNotification color={color} message={mensagem} theme={theme} />;
  //     },
  //     position: 'full',
  //   });

  // ----------------------------------------------- //

  // ---------------- Validation -------------------- //
  //   const [errors, setErrors] = useState({});

  //   const validation = (fieldValues = data) => {
  //     const temp = { ...errors };
  //     if ('encarregado' in fieldValues)
  //       temp.encarregado =
  //         fieldValues.encarregado == null || Object.keys(fieldValues.encarregado).length <= 0
  //           ? 'Campo Obrigatório'
  //           : '';

  //     if ('funcionarios' in fieldValues)
  //       temp.funcionarios = fieldValues.funcionarios.length != 0 ? '' : 'Campo Obrigatório';

  //     setErrors({
  //       ...temp,
  //     });

  //     if (fieldValues == data) {
  //       return Object.values(temp).every((x) => x == '');
  //     }
  //     return false;
  //   };
  // ----------------------------------------------- //

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const yData = [
      {
        id: infoValIndiv.id,
        qtd: infoValIndiv.qtd,
        tipoatrasosId: infoValIndiv.tipoatrasos_id,
        tipoperiodosId: infoValIndiv.tipoperiodos_id,
        tipofaltasId: infoValIndiv.tipofaltas_id,
        faltou: infoValIndiv.faltou,
        observacoesRH: infoValIndiv.observacoesRH,
        validadoRH: infoValIndiv.validadoRH,
        validadoAD: infoValIndiv.validadoAD,
        observacoesAD: infoValIndiv.observacoesAD,
      },
    ];

    dispatch(EnviarValidacaoIndividualAD(yData)).then(() =>
      navigate('/app/validacao/administracao'),
    );
  };
  const [formatedDate, setFormatedDate] = useState(null);
  useEffect(() => {
    if (Object.keys(infoValIndiv).length > 0) {
      setFormatedDate(format(new Date(infoValIndiv && infoValIndiv.data), 'dd/MM/yyyy'));
    }
  }, [infoValIndiv]);

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardBody>
          <Col md={12} sm={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4 className="text-underline-title-dark">
                {infoValIndiv && infoValIndiv.obras_id} -{' '}
                {infoValIndiv && infoValIndiv.descricaoObra}
              </h4>
            </Box>
          </Col>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Col md={12} sm={12}>
            <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
              <h4
                style={{
                  width: '100%',
                }}
                className="text-underline-title"
              >
                Data
              </h4>
            </Box>
            <TextField
              size="small"
              variant="outlined"
              value={infoValIndiv && formatedDate}
              disabled
            />

            {infoValIndiv ? (
              <>
                <RoleEncarregado
                  infoValIndiv={infoValIndiv}
                  tiposperiodos={tiposperiodos}
                  tiposatrasos={tiposatrasos}
                  tiposfaltas={tiposfaltas}
                  classes={classes}
                  disabledFaltou={disabledFaltou}
                  setDisabledFaltou={setDisabledFaltou}
                  valueTipoFaltaAutoComplete={valueTipoFaltaAutoComplete}
                  setValueTipoFaltaAutoComplete={setValueTipoFaltaAutoComplete}
                  width={width}
                />

                <RoleChefeDepartamento
                  infoValIndiv={infoValIndiv}
                  tiposperiodos={tiposperiodos}
                  tiposatrasos={tiposatrasos}
                  tiposfaltas={tiposfaltas}
                  classes={classes}
                  width={width}
                />

                <RoleRecursosHumanos
                  infoValIndiv={infoValIndiv}
                  tiposperiodos={tiposperiodos}
                  tiposatrasos={tiposatrasos}
                  tiposfaltas={tiposfaltas}
                  classes={classes}
                  disabledFaltou={disabledFaltou}
                  setDisabledFaltou={setDisabledFaltou}
                  valueTipoFaltaAutoComplete={valueTipoFaltaAutoComplete}
                  setValueTipoFaltaAutoComplete={setValueTipoFaltaAutoComplete}
                  width={width}
                />

                <RoleAdministrador
                  infoValIndiv={infoValIndiv}
                  tiposperiodos={tiposperiodos}
                  tiposatrasos={tiposatrasos}
                  tiposfaltas={tiposfaltas}
                  classes={classes}
                  disabledFaltou={disabledFaltou}
                  setDisabledFaltou={setDisabledFaltou}
                  valueTipoFaltaAutoComplete={valueTipoFaltaAutoComplete}
                  setValueTipoFaltaAutoComplete={setValueTipoFaltaAutoComplete}
                  width={width}
                />
              </>
            ) : (
              <Navigate to="/app/validacao/administracao" />
            )}
            <Button
              onClick={(e) => {
                handleSubmit(e);
              }}
              className={loading ? classes.btnGuardarLoading : classes.btnGuardar}
              size="small"
              variant="contained"
              style={{ float: 'right' }}
              disabled={!!loading}
            >
              {/* {!loading && <IoMdSave style={{ color: 'white', marginRight: '10px' }} />} */}
              {loading && (
                <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
              )}
              Guardar
            </Button>
          </Col>
        </CardBody>
      </Card>
    </form>
  );
}

export default ValidacaoIndividualForm;

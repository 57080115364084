/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Col } from 'reactstrap';
import { FormHelperText, OutlinedInput, Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';

// password textfield
import IconButton from '@mui/material/IconButton';

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function FormSenha({
  // handleFields,
  user,
  onSubmit,
  setUser,
  // toggle,
  errors = null,
  validateOnChange = true,
  // validation,
  validationPassword,
}) {
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const [valuesConfirmar, setValuesConfirmar] = useState({
    amount: '',
    confirmarPassword: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange) validationPassword({ [event.target.name]: event.target.value });
  };
  const handleChangeConfirmarPassword = (prop) => (event) => {
    setValuesConfirmar({ ...valuesConfirmar, [prop]: event.target.value });

    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange) validationPassword({ [event.target.name]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfirmarPassword = () => {
    setValuesConfirmar({ ...valuesConfirmar, showPassword: !valuesConfirmar.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Col md={12} lg={12}>
      <form onSubmit={onSubmit}>
        <Box sx={{ mb: 2 }}>
          <FormControl
            variant="outlined"
            fullWidth
            {...(errors.password && {
              error: true,
              // , helperText: errors.password
            })}
          >
            <b>Palavra passe</b>
            <OutlinedInput
              id="standard-adornment-password"
              name="password"
              // label="Palavra-passe"
              size="small"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              // onChange={handleChangePassword('password')}
              onChange={handleChangePassword('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && (
              <FormHelperText style={{ color: 'red' }}>{errors.password}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl
            variant="outlined"
            fullWidth
            {...(errors.confirmarPassword && {
              error: true,
              // , helperText: errors.password
            })}
          >
            <b>Confirmar palavra passe</b>
            <OutlinedInput
              id="standard-adornment-password"
              name="confirmarPassword"
              size="small"
              // label="Palavra-passe"
              type={valuesConfirmar.showPassword ? 'text' : 'password'}
              value={valuesConfirmar.password}
              // onChange={handleChangePassword('password')}
              onChange={handleChangeConfirmarPassword('confirmarPassword')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmarPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {valuesConfirmar.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.confirmarPassword && (
              <FormHelperText style={{ color: 'red' }}>{errors.confirmarPassword}</FormHelperText>
            )}
          </FormControl>
        </Box>

        {/* <ButtonToolbar
            className="form__button-toolbar"
            style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
          >
            <Button color="success" type="submit" size="sm">
              Confirmar
            </Button>
            <Button type="button" onClick={toggle} size="sm">
              Cancelar
            </Button>
          </ButtonToolbar> */}
      </form>
    </Col>
  );
}

FormSenha.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(FormSenha);

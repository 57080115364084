/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router';
import { format, getMonth, getYear, getDate, addMonths, startOfMonth, endOfMonth } from 'date-fns';
import { useDispatch, useSelector, connect } from 'react-redux';
import { styled, withStyles } from '@mui/styles';

import { ColorPicker } from 'mui-color';

import Notification from 'rc-notification';
import {
  createTheme,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  ThemeProvider,
  IconButton,
  Box,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoricoMensal from '../../../../../../redux/actions/ObrasActions/HistoricoMensalAction';
import TabelaHistorico from './TabelaHistorico';
import InfoObra from '../../../../../../redux/actions/ObrasActions/InfoObraAction';
import CardInfoObra from './CardInfoObras';
import { FullWideNotification } from '../../../../../../shared/components/Notification';
import { ThemeProps } from '../../../../../../shared/prop-types/ReducerProps';

import NovoRecurso from './novoRecurso';
import RecursosDefinidos from '../../../../../../redux/actions/ObrasActions/RecursosDefinidosAction';
import corObraAction from '../../../../../../redux/actions/ObrasActions/CorObraAction';

import CoresObras from '../../../../../../redux/actions/ObrasActions/CoresObrasAction';

// Enviar Validacao apenas pelo admin
import EnviarValidacaoSoAdmin from '../../../../../../redux/actions/ObrasActions/validacaoSoAdmin/EnviarValidacaoAdmin';

// import { FullWideNotification } from '../../../../shared/components/Notification';
// import { ThemeProps } from '../../../../shared/prop-types/ReducerProps';
// import Notification from 'rc-notification';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#E21450' },
  },
});

const GreenCheckbox = withStyles({
  root: {
    color: '#E21450',
    '&$checked': {
      color: '#E21450',
    },
    background: 'transparent !important',
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

let notificationTC = null;

function CardHistorico({ theme, classes }) {
  const [expanded, setExpanded] = useState(false);
  const { width } = useWindowDimensions();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const ydata = useSelector((state) => state.obras.infoObra);
  const [corObra, setCorObra] = useState();
  const [oldCorObra, setOldCorObra] = useState();
  const [oldValidacao, setOldValidacao] = useState(null);
  const user = useSelector((state) => state.user);

  const [checkboxes, setCheckboxes] = React.useState({
    validacaoAdmin: false,
  });

  const [loadingCor, setLoadingCor] = useState(false);
  const [loadingValRestrita, setLoadingValRestrita] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const fdate = format(selectedDate, 'yyyy-MM-dd');
  const id = location.pathname.split('/')[3];

  const coresObras = useSelector((state) => state.obras.cores);

  const fetchInfoObra = () => {
    dispatch(InfoObra(id, navigate));
  };
  const fetchHistoricoMensal = () => {
    const dataInicio = format(startOfMonth(new Date(selectedDate)), 'yyyy-MM-dd');
    const dataFim = format(endOfMonth(new Date(selectedDate)), 'yyyy-MM-dd');

    // if(getDate(selectedDate) >= 25){
    //   console.log('Passei no')
    // }

    dispatch(HistoricoMensal(id, dataInicio, dataFim));
  };

  const fetchRecursos = () => {
    dispatch(RecursosDefinidos(id));
  };

  const fetchCoresObras = () => {
    dispatch(CoresObras());
  };

  useEffect(() => {
    dispatch({ type: 'GET_HISTORICO_MENSAL', payload: [] });
  }, []);
  useEffect(() => {
    fetchInfoObra();
  }, []);
  useEffect(() => {
    fetchHistoricoMensal();
  }, [fdate]);
  useEffect(() => {
    setCorObra(ydata && ydata.cor);
  }, [ydata, id]);
  useEffect(() => {
    setOldCorObra(ydata && ydata.cor);
  }, [ydata]);
  useEffect(() => {
    if (ydata && ydata.validacaoSoAdmin != undefined && oldValidacao == null) {
      setOldValidacao(ydata && ydata.validacaoSoAdmin);
    }
  }, [checkboxes]);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    setCheckboxes({
      ...checkboxes,
      validacaoAdmin: ydata && ydata.validacaoSoAdmin,
    });
  }, [ydata]);
  useEffect(() => {
    fetchCoresObras();
  }, []);
  useEffect(() => {
    fetchRecursos();
  }, []);

  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    Notification.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ----------------------------------------------- //

  // ---------- Change Restrição ------------------- //

  const handleChange = (event) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });

    // eslint-disable-next-line no-param-reassign
    ydata.validacaoSoAdmin = event.target.checked;

    dispatch({ type: 'GET_INFO_OBRAS', payload: { ...ydata } });
  };

  // ----------------------------------------------- //

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    // fetchHistoricoMensal();
  };

  // ----- Mudar cor de obra ----- //

  const handleChangeCorObra = (value) => {
    // setCorObra(value.toString());
    // console.log(value.css.backgroundColor);

    setCorObra(value);
    // fetchHistoricoMensal();
    // console.log(corObra);
  };
  const handleSubmitCorObra = (e) => {
    e.preventDefault();

    const color = () => {
      return `#${corObra.hex}`;
    };

    if (!coresObras.includes(color())) {
      setLoadingCor(true);
      dispatch(corObraAction(id, color().toString(), oldCorObra, show))
        .then(() => fetchInfoObra())
        .then(() => fetchHistoricoMensal())
        .then(() => setLoadingCor(false));
    } else {
      show('warning', 'A cor selecionada pertence a outra obra');
      setCorObra(corObra);
    }
  };

  // ------------------------ //

  // ----- Validação administrador ----- //
  const handleSubmitValAdmin = (e) => {
    e.preventDefault();
    setLoadingValRestrita(true);
    const yData = [
      {
        validacaoSoAdmin: checkboxes && checkboxes.validacaoAdmin == true ? 1 : 0,
      },
    ];

    dispatch(EnviarValidacaoSoAdmin(id, yData, checkboxes, oldValidacao, show))
      .then(() => fetchInfoObra())
      .then(() => setOldValidacao(ydata && ydata.validacaoSoAdmin))
      .then(() => setLoadingValRestrita(false));
  };

  // ------------------------------- //

  return (
    <>
      <Card>
        <CardBody>
          <div>
            {ydata === undefined ? (
              fetchInfoObra()
            ) : (
              <>
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <h4 className="text-underline-title-dark">
                    {ydata.id} - {ydata.descricao}
                  </h4>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: width < 1250 ? 'column' : 'row',
                      mt: 3,
                    }}
                  >
                    <Box
                      sx={{ width: width < 1250 ? null : '25%', mx: 2, mb: width < 1250 ? 3 : 0 }}
                    >
                      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                        <h4
                          style={{
                            width: '100%',
                          }}
                          className="text-underline-title"
                        >
                          Informação da Obra
                        </h4>
                      </Box>
                      {ydata.obraMorada1 ? (
                        <p>
                          <b>Morada:</b> {ydata.obraMorada1}
                        </p>
                      ) : null}
                      {ydata.obraCodigoPostal ? (
                        <p>
                          <b>Cód. Postal:</b> {ydata.obraCodigoPostal}
                        </p>
                      ) : null}

                      {ydata.obraDistrito ? (
                        <p>
                          <b>Distrito:</b> {ydata.obraDistrito}
                        </p>
                      ) : null}
                    </Box>
                    <Box
                      sx={{ width: width < 1250 ? null : '25%', mx: 2, mb: width < 1250 ? 3 : 0 }}
                    >
                      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                        <h4
                          style={{
                            width: '100%',
                          }}
                          className="text-underline-title"
                        >
                          Informação do Cliente
                        </h4>
                      </Box>
                      {ydata.clienteNome ? (
                        <p>
                          <b>Cliente:</b> {ydata.clienteNome}
                        </p>
                      ) : null}
                      {ydata.clienteMorada1 ? (
                        <p>
                          <b>Morada:</b> {ydata.clienteMorada1}
                        </p>
                      ) : null}
                      {ydata.clienteCodigoPostal ? (
                        <p>
                          <b>Cód.Postal:</b> {ydata.clienteCodigoPostal}
                        </p>
                      ) : null}
                      {ydata.clienteCidade ? (
                        <p>
                          <b>Distrito:</b> {ydata.clienteCidade}
                        </p>
                      ) : null}
                    </Box>
                    <Box
                      sx={{ width: width < 1250 ? null : '25%', mx: 2, mb: width < 1250 ? 3 : 0 }}
                    >
                      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                        <h4
                          style={{
                            width: '100%',
                          }}
                          className="text-underline-title"
                        >
                          Cor da Obra
                        </h4>
                      </Box>
                      <p />
                      <div style={{ display: 'flex' }}>
                        {user && user.permissoes.includes('ALTERAR_COR_OBRAS') ? (
                          <div style={{ display: 'flex' }}>
                            {/* ------------ Mudar cor de Obra--------------- */}
                            <ColorPicker
                              name="color"
                              value={corObra}
                              defaultValue={corObra}
                              hideTextfield
                              onChange={(value) => handleChangeCorObra(value)}
                            />
                            <Button
                              size="small"
                              onClick={handleSubmitCorObra}
                              disabled={corObra === oldCorObra || !!loadingCor}
                              className={classes.btnMudarCor}
                              // className={
                              //   loadingCor ? classes.btnMudarCorLoading : classes.btnMudarCor
                              // }
                            >
                              {/* {loadingCor && (
                                <CircularProgress
                                  size={14}
                                  style={{ color: '#9d9d9d', marginRight: '10px' }}
                                />
                              )} */}
                              Mudar Cor
                            </Button>
                            {/* --------------------------------------------- */}
                          </div>
                        ) : null}
                      </div>
                    </Box>
                    {user && user.permissoes.includes('OBRAS_VALIDACAO_ADMINISTRACAO') ? (
                      <Box
                        sx={{
                          width: width < 1250 ? null : '25%',
                          mx: 2,
                          mb: width < 1250 ? 3 : 0,
                        }}
                      >
                        <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                          <h4
                            style={{
                              width: '100%',
                            }}
                            className="text-underline-title"
                          >
                            Validação Restrita
                          </h4>
                        </Box>

                        <div style={{ display: 'flex', marginTop: '8px' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkboxes && checkboxes.validacaoAdmin}
                                  onChange={handleChange}
                                  name="validacaoAdmin"
                                />
                              }
                              label="Apenas Administrador"
                            />
                          </FormGroup>
                          <Button
                            // color="primary"
                            variant="contained"
                            onClick={handleSubmitValAdmin}
                            disabled={
                              (checkboxes && checkboxes.validacaoAdmin == oldValidacao) ||
                              loadingValRestrita
                            }
                            className={classes.btnGuardar}
                          >
                            {/* {loadingValRestrita && (
                                    <CircularProgress
                                      size={14}
                                      style={{ color: '#9d9d9d', marginRight: '10px' }}
                                    />
                                  )} */}
                            Guardar
                          </Button>
                        </div>
                      </Box>
                    ) : null}
                  </Box>
                </Collapse>
              </>
            )}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Box sx={{ margin: 2 }}>
            <Box sx={{ borderBottom: '1px solid #000', mb: 5 }}>
              <h4
                style={{
                  width: '100%',
                }}
                className="text-underline-title"
              >
                Funcionários
              </h4>
            </Box>
            {ydata && Object.keys(ydata).length > 0 ? (
              <NovoRecurso
                id={id}
                infoObra={ydata}
                fetchRecursos={fetchRecursos}
                fetchInfoObra={fetchInfoObra}
                classes={classes}
                width={width}
              />
            ) : null}
          </Box>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Box sx={{ margin: 2 }}>
            <Box sx={{ borderBottom: '1px solid #000', mb: 5 }}>
              <h4
                style={{
                  width: '100%',
                }}
                className="text-underline-title"
              >
                Histórico
              </h4>
            </Box>
            <ThemeProvider theme={defaultMaterialTheme}>
              <TabelaHistorico
                fdate={selectedDate}
                setFdate={setSelectedDate}
                id={id}
                obra={ydata}
                fetchHistoricoMensal={fetchHistoricoMensal}
                classes={classes}
                handleDateChange={handleDateChange}
              />
            </ThemeProvider>
          </Box>
        </CardBody>
      </Card>
    </>
  );
}

CardHistorico.propTypes = {
  theme: ThemeProps.isRequired,
  // theme: ThemeProps,
};

export default connect((state) => ({
  theme: state.theme,
}))(CardHistorico);

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import Logo from '../../../assets/logo_gestao.png';
// import TopbarNotification from "./TopbarNotification";
import { UserProps } from '../../../shared/prop-types/ReducerProps';

function Topbar({ changeMobileSidebarVisibility, changeSidebarVisibility, user }) {
  return (
    <div className="topbar">
      <div className="topbar__left">
        <TopbarSidebarButton
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
          changeSidebarVisibility={changeSidebarVisibility}
        />
        <Link className="topbar__logo" to="/app">
          <img
            src={Logo}
            alt=""
            style={{
              width: '40px',
            }}
          />
          <h4
            style={{
              marginLeft: '20px',
              fontSize: '20px',
              color: '#000000',
              width: '100%',
            }}
          >
            {process.env.REACT_APP_TITLE}
          </h4>
        </Link>
      </div>
      <div className="topbar__right">
        <div className="topbar__right-over">
          {/* <TopbarNotification /> */}
          <TopbarProfile user={user} />
        </div>
      </div>
    </div>
  );
}

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  user: UserProps.isRequired,
};

export default Topbar;

/* eslint-disable default-param-last */
import { validacaoInitialState } from '../InitialState/validacao';

const validacaoReducer = (state = validacaoInitialState, { type, payload }) => {
  switch (type) {
    // VALIDAÇÃO CHEFE DE DEPARTAMENTO
    case 'GET_VALIDACAO_CD':
      return {
        ...state,
        validacaoCD: payload,
      };
    case 'GET_OBRAS_VALIDACAO':
      return {
        ...state,
        obrasValidacao: payload,
      };
    case 'GET_FUNCIONARIOS_VALIDACAO':
      return {
        ...state,
        funcionariosValidacao: payload,
      };
    case 'POST_VALIDACAO_CD':
      return {
        ...state,
        enviarValidacaoCD: payload,
      };

    case 'GET_VALIDACAO_INDIVIDUAL_CD':
      return {
        ...state,
        validacaoIndividualCD: payload,
      };
    case 'POST_VALIDACAO_INDIVIDUAL_CD':
      return {
        ...state,
        enviarValidacaoIndividualCD: payload,
      };

    // VALIDAÇÃO RECURSOS HUMANOS
    case 'GET_VALIDACAO_RH':
      return {
        ...state,
        validacaoRH: payload,
      };
    case 'GET_VALIDACAO_INDIVIDUAL_RH':
      return {
        ...state,
        validacaoIndividualRH: payload,
      };
    case 'POST_VALIDACAO_RH':
      return {
        ...state,
        enviarValidacaoRH: payload,
      };
    case 'POST_VALIDACAO_INDIVIDUAL_RH':
      return {
        ...state,
        enviarValidacaoIndividualRH: payload,
      };

    // VALIDAÇÃO ADMINISTRAÇÃO
    case 'GET_VALIDACAO_AD':
      return {
        ...state,
        validacaoAD: payload,
      };
    case 'GET_VALIDACAO_INDIVIDUAL_AD':
      return {
        ...state,
        validacaoIndividualAD: payload,
      };
    case 'POST_VALIDACAO_AD':
      return {
        ...state,
        enviarValidacaoAD: payload,
      };
    case 'POST_VALIDACAO_INDIVIDUAL_AD':
      return {
        ...state,
        enviarValidacaoIndividualAD: payload,
      };
    case 'GET_OBRAS_VALIDACAO_AD':
      return {
        ...state,
        todasObrasValAd: payload,
      };
    case 'GET_FUNCIONARIOS_VALIDACAO_AD':
      return {
        ...state,
        todosFuncionariosValAd: payload,
      };

    // DELETE REGISTO HORAS
    case 'DELETE_REGISTO_HORAS':
      return {
        ...state,
        registoHorasDeleted: payload,
      };

    // DADOS FORMULÁRIO
    case 'DADOS_FORMULARIO_VALIDACAO':
      return {
        ...state,
        dadosFormularioValidacao: payload,
      };

    // Obras validadas apenas pelo Utilizador
    case 'VALIDACAO_OBRA_ADMIN':
      return {
        ...state,
        validacaoObraAdmin: payload,
      };

    default:
      return state;
  }
};

export default validacaoReducer;

/* eslint-disable default-param-last */
import { globalInitialState } from '../InitialState/global';

const globalReducer = (state = globalInitialState, { type, payload }) => {
  switch (type) {
    // FILTERS
    case 'GET_ESTADOS_PROJETO':
      return {
        ...state,
        estadosProjeto: payload,
      };
    case 'GET_ESTADOS_SUB_PROJETO':
      return {
        ...state,
        estadosSubProjeto: payload,
      };

    // TABLE PAGINATION

    case 'CRIAR_EQUIPAS_TABLE_PAGINATION':
      return {
        ...state,
        criarEquipasTablePage: payload,
      };

    case 'REG_HORAS_OPERACIONAIS_TABLE_PAGINATION':
      return {
        ...state,
        regHorasOperacionaisTablePage: payload,
      };
    case 'REG_HORAS_ESCRITORIO_TABLE_PAGINATION':
      return {
        ...state,
        regHorasEscritorioTablePage: payload,
      };
    case 'OBRAS_TABLE_PAGINATION':
      return {
        ...state,
        obrasTablePage: payload,
      };
    case 'VAL_CD_TABLE_PAGINATION':
      return {
        ...state,
        valCDTablePage: payload,
      };
    case 'VAL_RH_TABLE_PAGINATION':
      return {
        ...state,
        valRHTablePage: payload,
      };
    case 'VAL_AD_TABLE_PAGINATION':
      return {
        ...state,
        valADTablePage: payload,
      };
    case 'UTILIZADORES_TABLE_PAGINATION':
      return {
        ...state,
        utilizadoresTablePage: payload,
      };
    case 'FUNCIONARIOS_TABLE_PAGINATION':
      return {
        ...state,
        funcionariosTablePage: payload,
      };

    // SEARCH

    case 'CRIAR_EQUIPAS_TABLE_SEARCH':
      return {
        ...state,
        criarEquipasTableSearch: payload,
      };

    case 'REG_HORAS_OPERACIONAIS_TABLE_SEARCH':
      return {
        ...state,
        regHorasOperacionaisTableSearch: payload,
      };
    case 'REG_HORAS_ESCRITORIO_TABLE_SEARCH':
      return {
        ...state,
        regHorasEscritorioTableSearch: payload,
      };
    case 'OBRAS_TABLE_SEARCH':
      return {
        ...state,
        obrasTableSearch: payload,
      };
    case 'VAL_CD_TABLE_SEARCH':
      return {
        ...state,
        valCDTableSearch: payload,
      };
    case 'VAL_RH_TABLE_SEARCH':
      return {
        ...state,
        valRHTableSearch: payload,
      };
    case 'VAL_AD_TABLE_SEARCH':
      return {
        ...state,
        valADTableSearch: payload,
      };
    case 'UTILIZADORES_TABLE_SEARCH':
      return {
        ...state,
        utilizadoresTableSearch: payload,
      };
    case 'FUNCIONARIOS_TABLE_SEARCH':
      return {
        ...state,
        funcionariosTableSearch: payload,
      };

    default:
      return state;
  }
};

export default globalReducer;

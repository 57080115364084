import React from 'react';
import { Button } from '@mui/material';

function HorasButtonsPeriodo({ recurso, classes, handleData }) {
  return (
    <div style={{ marginLeft: '20px' }}>
      {recurso.qtdPeriodo <= 0 ? null : (
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtdPeriodo - 1);
          }}
          className={classes.buttons}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          -
        </Button>
      )}

      <Button
        className={classes.buttons}
        color="primary"
        size="sm"
        onClick={() => {
          handleData(recurso.qtdPeriodo + 1);
        }}
        disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
      >
        +
      </Button>

      <Button
        className={classes.buttons}
        color="primary"
        size="sm"
        onClick={() => {
          handleData(recurso.qtdPeriodo + 4);
        }}
        disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
      >
        +4h
      </Button>

      <Button
        className={classes.buttons}
        color="primary"
        size="sm"
        onClick={() => {
          handleData(recurso.qtdPeriodo + 8);
        }}
        disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
      >
        +8h
      </Button>
    </div>
  );
}

export default HorasButtonsPeriodo;

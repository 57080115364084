/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';
// import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse, IconButton, Menu, MenuItem } from '@mui/material';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import { hookAuth0 } from '../../../shared/components/auth/withAuth0';
import TopbarMenuLink from './TopbarMenuLink';

function TopbarProfile({ user }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.clear('persist:root');
    navigate('/login');
  };

  return (
    <div className="topbar__profile">
      {/* <button className="topbar__avatar" type="button" onClick={toggleProfile}>
          <p className="topbar__avatar-img" />
          <p className="topbar__avatar-name">
            {user.nome} <BiUserCircle size={30} />
          </p>
        </button>
        {isCollapsed && <button className="topbar__back" type="button" onClick={toggleProfile} />}
        <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Sair" icon="exit" path="/login" onClick={logout} />
          </div>
        </Collapse>{' '} */}
      {/* <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img className="topbar__avatar-img" src={Worker} alt="avatar" />
        <p className="topbar__avatar-name">{user.nome}</p>

        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && <button className="topbar__back" type="button" onClick={toggleProfile} />}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="Sair" icon="exit" path="/login" onClick={logout} />
        </div>
      </Collapse>
    </div> */}
      {/* Novo Icon sair */}
      <div className="topbar__menu">
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <BiUserCircle size={30} />
        </IconButton>
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open1}
        onClose={handleClose}
      >
        <div>
          <MenuItem onClick={logout}>Sair</MenuItem>
        </div>
      </Menu>
    </div>
  );
}

TopbarProfile.propTypes = {
  user: UserProps.isRequired,
};

export default hookAuth0(TopbarProfile);

import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
// import Link from "@mui/material/Link";

function BreadColaboradoresAtivos() {
  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumb"
      style={{ marginBottom: '10px', marginTop: '12px' }}
    >
      <Link to="/app" color="textPrimary">
        Inicio
      </Link>
      <Typography color="textPrimary">Relatório Colaboradores Ativos</Typography>
    </Breadcrumbs>
  );
}
export default BreadColaboradoresAtivos;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import orange from '@mui/material/colors/orange';
import { Button } from '@mui/material';

import BreadRegistarHorasEscritorio from '../../../../App/Breadcrumbs/RegistarHoras/Escritorios/BreadRegistarHoras';

import RegistoHorasEscritorio from './components/RegistoHorasEscritorio';
import FooterLinks from '../../../../Account/Footer';

import TiposFaltas from '../../../../../redux/actions/TiposFaltasAction/tiposFaltasDropdownAction';
import TiposPeriodos from '../../../../../redux/actions/TiposPeriodosAction/tiposPeriodosDropdownAction';
import TiposAtrasos from '../../../../../redux/actions/TiposAtrasosAction/tiposAtrasosDropdownAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnAdicionar: {
    color: '#FFFFFF',
    backgroundColor: '#E21450',
    '&:hover': {
      backgroundColor: '#44c9a3',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#44c9a3',
      },
    },
    width: '200px',
    height: '40px',
  },

  btnGuardar: {
    color: 'white',
    backgroundColor: orange[600],
    '&:hover': {
      backgroundColor: orange[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: orange[600],
      },
    },
    width: '120px',
    height: '40px',
  },
  btnVoltar: {
    height: '30px',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
}));
// ---------------------------- Window dimensions -------------------------//

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
// ---------------------------------------------------------------------//

function Horas() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TiposFaltas());
    dispatch(TiposPeriodos());
    dispatch(TiposAtrasos());
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadRegistarHorasEscritorio />
            <h3 className="page-title" style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to="/app/registarHoras/escritorio" style={{ textDecoration: 'none' }}>
                <ButtonToolbar
                  className="btn-toolbar--center"
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <Button id="TooltipBottom" className={classes.btnVoltar} color="secondary">
                    <span
                      className="lnr lnr-chevron-left"
                      style={{ fontSize: 10, fontWeight: 900 }}
                    />
                  </Button>

                  <UncontrolledTooltip
                    // dir={dir}
                    placement="bottom"
                    target="TooltipBottom"
                  >
                    Voltar
                  </UncontrolledTooltip>
                </ButtonToolbar>
              </Link>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                }}
                className="text-underline"
              >
                Registar Horas De Escritório
              </h4>
            </h3>
          </Col>
        </Row>
        <Row>
          <RegistoHorasEscritorio classes={classes} useWindowDimensions={useWindowDimensions} />
        </Row>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
}

export default Horas;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';

// Info Gráficos
import InfoGraficos from '../../../redux/actions/Dashboard/infoGraficosAction';

function AppDashboard({ graficos }) {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  graficos = useSelector((state) => state.dashboard.graficos);
  const dispatch = useDispatch();

  const [funcionariosTotal, setFuncionariosTotal] = useState(0);
  const [funcionariosAlocados, setFuncionariosAlocados] = useState(0);
  const [alocacaoGrafico, setAlocacaoGrafico] = useState([]);

  const [obrasAbertas, setObrasAbertas] = useState(0);
  const [obrasEstadoTotal, setObrasEstadoTotal] = useState(0);
  const [obrasEstadoGrafico, setObrasEstadoGrafico] = useState([]);
  const [obrasPorDistrito, setObrasPorDistrito] = useState([]);
  const [chefeDepartamentoSemUser, setChefeDepartamentoSemUser] = useState([]);

  const userRole = useSelector((state) => state.user.role);

  // Aqui podemos consultar o state que registado no redux
  let { AlocacaoRecursos } = graficos;
  let { EstadosObras } = graficos;

  if (graficos.length <= 0) {
    dispatch(InfoGraficos());
  }

  const boundAction = useCallback(() => dispatch(InfoGraficos()), [dispatch]);
  const fetchGraficos = () => {
    dispatch(InfoGraficos());
  };

  useEffect(() => {
    // Aqui os dados sao carregados quando a página inicia
    fetchGraficos();
    // Aqui podemos consultar o state que registado no redux
    AlocacaoRecursos = graficos.AlocacaoRecursos;
    EstadosObras = graficos.EstadosObras;

    setAlocacaoGrafico([
      { value: graficos.AlocacaoRecursos.recursosAlocados, fill: '#ff4861' },
      { value: graficos.AlocacaoRecursos.recursosNaoAlocados, fill: '#eeeeee' },
    ]);

    setFuncionariosAlocados(
      (
        (AlocacaoRecursos.recursosAlocados * 100) /
        (AlocacaoRecursos.recursosAlocados + AlocacaoRecursos.recursosNaoAlocados)
      ).toFixed(1),
    );
    setFuncionariosTotal(AlocacaoRecursos.recursosAlocados + AlocacaoRecursos.recursosNaoAlocados);

    setObrasPorDistrito(graficos.ObrasPorDistrito);
    setChefeDepartamentoSemUser(graficos.ChefeDepSemUser);
    setObrasAbertas(EstadosObras.obrasAbertas);
    setObrasEstadoTotal(EstadosObras.obrasAbertas + EstadosObras.obrasFechadas);
    setObrasEstadoGrafico([
      { value: EstadosObras.obrasAbertas, fill: '#E21450' },
      { value: EstadosObras.obrasFechadas, fill: '#eeeeee' },
    ]);
  }, [boundAction, AlocacaoRecursos.recursosAlocados]);

  const columnsTableObrasPorDistrito = [
    {
      name: 'id',
      label: 'Distritos',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '300px' } }),
      },
    },
    {
      name: 'val',
      label: 'Total',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { minWidth: '100px', maxWidth: '300px' } }),
      },
    },
  ];
  const optionsTableObrasPorDistrito = {
    filter: false,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 0,
    pagination: false,
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        toolTip: 'Dia',
        columnHeaderTooltip: (column) => `Dia ${column.label}`,
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  const columnsTableChefeDepSemUser = [
    {
      name: 'nome2',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '300px' } }),
      },
    },
    {
      name: 'totalObras',
      label: 'Total de Obras',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { minWidth: '100px', maxWidth: '300px' } }),
      },
    },
  ];
  const optionsTableChefeDepSemUser = {
    filter: false,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 0,
    pagination: false,
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        toolTip: 'Dia',
        columnHeaderTooltip: (column) => `Dia ${column.label}`,
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  return (
    <Container className="dashboard">
      {/* <Row>
          <Col md={12}>
            <h3 className="page-title">Bem-vindo à {process.env.REACT_APP_TITLE}</h3>
          </Col>
        </Row> */}
      <Row>
        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
          <Card>
            <CardBody className="dashboard__health-chart-card">
              <div className="card__title">
                <h5 className="bold-text card__title-center">Funcionários Alocados</h5>
              </div>
              <div className="dashboard__health-chart">
                <ResponsiveContainer height={180}>
                  <PieChart>
                    <Pie
                      data={alocacaoGrafico}
                      dataKey="value"
                      cy={85}
                      innerRadius={80}
                      outerRadius={90}
                    />
                  </PieChart>
                </ResponsiveContainer>
                <div className="dashboard__health-chart-info">
                  <p className="dashboard__health-chart-number">{funcionariosAlocados} %</p>
                  <p className="dashboard__health-chart-units">Funcionários Alocados</p>
                </div>
              </div>
              <p className="dashboard__goal">Total: {funcionariosTotal} Funcionários</p>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
          <Card>
            <CardBody className="dashboard__health-chart-card">
              <div className="card__title">
                <h5 className="bold-text card__title-center">Obras Abertas</h5>
              </div>
              <div className="dashboard__health-chart">
                <ResponsiveContainer height={180}>
                  <PieChart>
                    <Pie
                      data={obrasEstadoGrafico}
                      dataKey="value"
                      cy={85}
                      innerRadius={80}
                      outerRadius={90}
                    />
                  </PieChart>
                </ResponsiveContainer>
                <div className="dashboard__health-chart-info">
                  <p className="dashboard__health-chart-number">{obrasAbertas}</p>
                  <p className="dashboard__health-chart-units">Obras Abertas</p>
                </div>
              </div>
              <p className="dashboard__goal">Total: {obrasEstadoTotal} Obras</p>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
          <Card />
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
          <Card />
        </Col>
      </Row>
      {userRole === 2 && (
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card>
              <CardBody className="dashboard__health-chart-card">
                <div className="card__title">
                  <h5 className="bold-text card__title-center">Obras por Distrito</h5>
                </div>
                <div className="dashboard__health-chart">
                  <MUIDataTable
                    title="Distritos"
                    description="Nada para mostrar"
                    data={obrasPorDistrito}
                    columns={columnsTableObrasPorDistrito}
                    options={optionsTableObrasPorDistrito}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card>
              <CardBody className="dashboard__health-chart-card">
                <div className="card__title">
                  <h5 className="bold-text card__title-center">
                    Chefes de Departamento sem acesso
                  </h5>
                </div>
                <div className="dashboard__health-chart">
                  <MUIDataTable
                    title="Chefes de Departamento"
                    description="Nada para mostrar"
                    data={chefeDepartamentoSemUser}
                    columns={columnsTableChefeDepSemUser}
                    options={optionsTableChefeDepSemUser}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}

AppDashboard.propTypes = {};

export default connect((state) => ({
  rtl: state.rtl,
  graficos: state.dashboard.graficos,
}))(AppDashboard);

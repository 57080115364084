/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable object-shorthand */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

import './tiposatrasos.css';
// PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { CardBody, Col, Container, Row } from 'reactstrap';

import { Button, Box, TextField, InputAdornment, IconButton } from '@mui/material';

import MUIDataTable from 'mui-datatables';

// COMPONENTS
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';
import BreadTiposAtrasos from '../../../App/Breadcrumbs/Administracao/TiposAtrasos/BreadTiposAtrasos';
import FooterLinks from '../../../Account/Footer';
// ACTIONS
import GetTiposAtrasos from '../../../../redux/actions/TiposAtrasosAction/tiposAtrasosAction';
import GetTipoAtraso from '../../../../redux/actions/TiposAtrasosAction/getTipoAtrasoAction';
// NOTIFICATION
import Notification from 'rc-notification';
import { FullWideNotification } from '../../../../shared/components/Notification';
import { FiEdit, FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi';

let notificationTC = null;

function TiposAtrasos(theme) {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [tipoAtraso, setTipoAtraso] = useState({
    descricao: '',
  });
  const [tipoAtrasoId, setTipoAtrasoId] = useState();
  const [tipoAtrasoNome, setTipoAtrasoNome] = useState();
  const [errorsTipoAtraso, setErrorsTipoAtraso] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // ====================== Search Text ====================== //

  const [searchText, setSearchText] = useState();

  // ========================================================= //

  const resetTipoAtraso = () => {
    setTipoAtraso({
      descricao: '',
    });
  };

  const tiposAtrasos = useSelector((state) => state.tiposAtrasos.tiposAtrasos);

  const fetchTiposAtrasos = () => {
    dispatch(GetTiposAtrasos());
    dispatch({ type: 'CLEAR_DATA_TIPOATRASO' });
  };

  const tipoAtrasoInfo = useSelector((state) => state.tiposAtrasos.tipoAtraso);

  const fetchTipoAtraso = (id, e) => {
    e.preventDefault();
    return dispatch(GetTipoAtraso(id));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchTiposAtrasos();
  }, []);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsTipoAtraso({});
    setLoading(false);
    if (btn == 'edit') {
      setTipoAtrasoId(id);
      setTipoAtrasoNome(tableMeta.rowData[0]);
      fetchTipoAtraso(id, e).then(() => setOpenEdit(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setTipoAtrasoId(id);
      setTipoAtrasoNome(tableMeta.rowData[0]);
    } else {
      setOpenAdicionar(true);
      resetTipoAtraso();
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setErrorsTipoAtraso({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // dispatch({ type: 'CLEAR_DATA_TIPOAtraso' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
      setErrorsTipoAtraso({});
    }
  };

  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    Notification.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ----------------------------------------------- //

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              <Button
                onClick={(e) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value, tableMeta)
                }
              >
                <FiEdit size={30} strokeWidth={1} color="#000" />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
              >
                <FiTrash2 size={30} strokeWidth={1} color="#000" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    search: false,
    searchText: searchText,
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    // customToolbar: () => {
    //   return <CustomToolbar handleOpen={handleOpen} />;
    // },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------------- Validacao tipos de atrasos --------------------//

  const validationTipoAtraso = (fieldValues = tipoAtraso) => {
    const temp = { ...errorsTipoAtraso };
    if ('descricao' in fieldValues)
      temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';

    setErrorsTipoAtraso({
      ...temp,
    });
    if (fieldValues == tipoAtraso) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadTiposAtrasos />
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
              }}
              className="text-underline"
            >
              Tipos de Atrasos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <>
              <ModalAdicionar
                openAdicionar={openAdicionar}
                handleClose={handleClose}
                tipoAtraso={tipoAtraso}
                setTipoAtraso={setTipoAtraso}
                validationTipoAtraso={validationTipoAtraso}
                errorsTipoAtraso={errorsTipoAtraso}
                fetchTiposAtrasos={fetchTiposAtrasos}
                show={show}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
              {/* <MuiThemeProvider theme={myTheme}> */}
              <ModalEdit
                openEdit={openEdit}
                handleClose={handleClose}
                tipoAtraso={tipoAtraso}
                setTipoAtraso={setTipoAtraso}
                validationTipoAtraso={validationTipoAtraso}
                errorsTipoAtraso={errorsTipoAtraso}
                fetchTiposAtrasos={fetchTiposAtrasos}
                tipoAtrasoInfo={tipoAtrasoInfo}
                tipoAtrasoId={tipoAtrasoId}
                tipoAtrasoNome={tipoAtrasoNome}
                show={show}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
              <ModalDelete
                openDelete={openDelete}
                handleClose={handleClose}
                tipoAtrasoId={tipoAtrasoId}
                fetchTiposAtrasos={fetchTiposAtrasos}
                tipoAtraso={tipoAtraso}
                tipoAtrasoNome={tipoAtrasoNome}
                show={show}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
              <CardBody>
                <Col md={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Box>
                      <b>Pesquisar</b>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="search"
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <FiSearch />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      <Button
                        sx={{ border: '1px solid #000', minWidth: '45px', mt: 2.5 }}
                        onClick={(e) => handleOpen('add', e)}
                      >
                        <FiPlus size={25} strokeWidth={1.5} />
                      </Button>
                    </Box>
                  </Box>
                  <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                    <h4
                      style={{
                        width: '100%',
                      }}
                      className="text-underline-title"
                    >
                      Tipos de Atrasos
                    </h4>
                  </Box>
                  <MUIDataTable
                    description="Nada para mostrar"
                    data={tiposAtrasos}
                    columns={columns}
                    options={options}
                  />
                </Col>
              </CardBody>
              {/* </MuiThemeProvider> */}
            </>
          </Col>
        </Row>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
}

export default TiposAtrasos;

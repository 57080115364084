/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// MUI
import { TextField, FormControlLabel, Checkbox, Autocomplete, Box } from '@mui/material';
// Picker
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptLocale from 'date-fns/locale/pt';

// Components
import HorasButtons from './HorasButtons';

function RegistoForm({
  novoRegisto,
  setNovoRegisto,
  classes,
  setHoras,
  validateOnChange = true,
  valueTipoAtrasoAutoComplete,
  setValueTipoAtrasoAutoComplete,
  valueTipoPeriodoAutoComplete,
  setValueTipoPeriodoAutoComplete,
  tiposperiodos,
  tiposatrasos,
  validationNovoRegistoDiario,
  errorsNovoRegistoDiario,
  horas,
  // Campos
  setCheckbox,
  setDisabledFaltou,
}) {
  const handleDataInicioChange = (date) => {
    setNovoRegisto({
      ...novoRegisto,
      dataInicio: date,
    });
    console.log(novoRegisto.dataInicio);
    if (validateOnChange) validationNovoRegistoDiario({ dataInicio: date });
  };

  const handleData = (currentHoras) => {
    // eslint-disable-next-line no-param-reassign
    // novoRegisto.qtd = currentHoras;
    setHoras(currentHoras);
    setNovoRegisto({
      ...novoRegisto,
      horas: currentHoras,
      qtd: currentHoras,
    });
  };

  useEffect(() => {
    if (horas > 24) {
      setHoras(8);
      setNovoRegisto({
        ...novoRegisto,
        horas: 8,
        qtd: 8,
      });
    }
  }, [horas]);

  // ------------------------------ Handle Falta--------------------------//

  const handleFaltas = (falta) => {
    setCheckbox(falta);

    if (novoRegisto) {
      // eslint-disable-next-line no-param-reassign
      novoRegisto.faltou = falta;
      setDisabledFaltou(!falta);
    }
  };

  // --------------------------------------------------------------------//

  // ------------------------ Handle Autocompletes----------------------//
  const handleChangeAtrasos = (value) => {
    setValueTipoAtrasoAutoComplete(value);
    if (value) {
      setNovoRegisto({
        ...novoRegisto,
        tipoAtrasoId: value,
      });
    } else {
      setNovoRegisto({
        ...novoRegisto,
        tipoAtrasoId: { id: 0, descricao: '' },
      });
    }
  };

  const handleChangePeriodo = (value) => {
    setValueTipoPeriodoAutoComplete(value);
    if (value) {
      setNovoRegisto({
        ...novoRegisto,
        tipoPeriodoId: value,
      });
    } else {
      setNovoRegisto({
        ...novoRegisto,
        tipoPeriodoId: { id: 0, descricao: '' },
      });
    }
  };
  // --------------------------------------------------------------------//

  const handleChangeObservacoes = (e) => {
    setNovoRegisto({
      ...novoRegisto,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
          <b>Data</b>
          <DatePicker
            style={{ width: '100%' }}
            margin="normal"
            id="date-picker-dialog"
            format="dd/MM/yyyy"
            okLabel="ok"
            cancelLabel="cancelar"
            value={novoRegisto && novoRegisto.dataInicio}
            onChange={handleDataInicioChange}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'dd/mm/aaaa',
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                variant="outlined"
                size="small"
              />
            )}
            disabled
            // helperText="Data inválida"
            {...(errorsNovoRegistoDiario.dataInicio && {
              error: true,
              helperText: errorsNovoRegistoDiario.dataInicio,
            })}
          />
        </LocalizationProvider>
      </Box>
      <div className="falta-horas">
        <div className="faltaDiario">
          <FormControlLabel
            control={
              <Checkbox
                checked={novoRegisto.faltou}
                onChange={() => {
                  handleFaltas(!novoRegisto.faltou);
                }}
                name="falta"
              />
            }
            label="Falta"
            // style={{ width: '20%' }}
          />
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '70%' }}>
          <Box className={classes.horasBorderPeriodoTextfield}>
            <h4>{novoRegisto.qtd}h</h4>
          </Box>
          <HorasButtons recurso={novoRegisto} classes={classes} handleData={handleData} />
        </Box>
      </div>

      <div>
        <div className="autocompletes">
          {novoRegisto && novoRegisto.faltou == true ? (
            <div className="tipoPeriodo">
              <b>Tipo de Periodo</b>
              <Autocomplete
                id="tipoPeriodoId"
                name="tipoPeriodoId"
                noOptionsText="Sem dados"
                onChange={(e, value) => handleChangePeriodo(value)}
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                value={valueTipoPeriodoAutoComplete}
                options={tiposperiodos || [{ id: 0, descricao: '' }]}
                getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                filterOptions={(options, state) => {
                  return options.filter(
                    (option) =>
                      option.id > 0 &&
                      `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
                  );
                }}
                getOptionSelected={(option, value) => {
                  // if (!value.id) return true;

                  return value.id === option.id;
                }}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tipo de Período"
                    variant="outlined"
                    size="small"
                    // required
                    // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                  />
                )}
              />
            </div>
          ) : null}

          <div
            className={
              novoRegisto.faltou == true ? 'periodo-atrasos-true' : 'periodo-atrasos-false'
            }
            //  style={novoRegisto.faltou == true ? { width: '48%' } : { width: '100%' }}
          >
            <b>Tipo de Atraso</b>
            <Autocomplete
              id="tipoAtrasoId"
              name="tipoAtrasoId"
              noOptionsText="Sem dados"
              onChange={(e, value) => handleChangeAtrasos(value)}
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              value={valueTipoAtrasoAutoComplete}
              options={tiposatrasos || [{ id: 0, descricao: '' }]}
              getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
              filterOptions={(options, state) => {
                return options.filter(
                  (option) =>
                    option.id > 0 &&
                    `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
                );
              }}
              getOptionSelected={(option, value) => {
                // if (!value.id) return true;

                return value.id === option.id;
              }}
              style={novoRegisto.faltou == true ? { width: '100%' } : { width: '100%' }}
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder="Observações"
                  // required
                  // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                />
              )}
            />
          </div>
        </div>
      </div>
      <Box sx={{ mt: 2 }}>
        <b>Observações</b>
        <TextField
          name="observacoes"
          variant="outlined"
          style={{ width: '100%' }}
          size="small"
          // disabled={
          //   recurso && (recurso.validadoAD || recurso.validadoRH || recurso.validadoCD)
          // }
          value={novoRegisto.observacoes || ''}
          onChange={handleChangeObservacoes}
        />
      </Box>
    </>
  );
}

export default RegistoForm;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Col, Container, Row, ButtonToolbar, UncontrolledTooltip, CardBody } from 'reactstrap';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey, red, yellow, green } from '@mui/material/colors';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CardUtilizador from './components/CardUtilizador';
import CardPermissoes from './components/permissoes/CardPermissoes';
import FuncionariosUtilizadores from '../../../../../redux/actions/UsersActions/funcionariosAction';

import BreadUtilizador from '../../../../App/Breadcrumbs/Administracao/Utilizadores/BreadUtilizador';

import FooterLinks from '../../../../Account/Footer';
// NOTIFICATION
import Notification from 'rc-notification';
import { FullWideNotification } from '../../../../../shared/components/Notification';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnGlobal: {
    color: 'grey',
    fontWeight: 'bold',
    border: '1px solid #000',
    backgroundColor: '#ebebeb',
    // borderRadius: '5px',
  },
  btnSenha: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
    width: '200px',
    height: '40px',
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[600],
      },
    },
    width: '200px',
    height: '40px',
  },
  btnEditar: {
    color: 'white',
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
    width: '200px',
    height: '40px',
  },
  btnGuardar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnGuardarLoading: {
    color: 'white',
    backgroundColor: grey[300],
    width: '120px',
    height: '40px',
  },
  btnVoltar: {
    height: '30px',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
}));

let notificationTC = null;

function DetalhesUtilizador(theme) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const location = useLocation();

  const userId = location.pathname.split('/')[4];
  const fetchFuncionariosUtilizadores = () => {
    dispatch(FuncionariosUtilizadores(userId));
  };

  useEffect(() => {
    fetchFuncionariosUtilizadores();
  }, []);

  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    Notification.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ----------------------------------------------- //

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadUtilizador />
            <h3 className="page-title" style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to="/app/administracao/utilizadores" style={{ textDecoration: 'none' }}>
                <ButtonToolbar
                  className="btn-toolbar--center"
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <Button
                    id="TooltipBottom"
                    outline
                    className={classes.btnVoltar}
                    color="secondary"
                    size="sm"
                  >
                    <span
                      className="lnr lnr-chevron-left"
                      style={{ fontSize: 10, fontWeight: 900 }}
                    />
                  </Button>

                  <UncontrolledTooltip
                    // dir={dir}
                    placement="bottom"
                    target="TooltipBottom"
                  >
                    Voltar
                  </UncontrolledTooltip>
                </ButtonToolbar>
              </Link>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                }}
                className="text-underline"
              >
                Utilizador
              </h4>
            </h3>
          </Col>
        </Row>
        <CardBody>
          <Col md={12}>
            <CardUtilizador
              fetchUtilizador={fetchFuncionariosUtilizadores}
              show={show}
              classes={classes}
            />

            <CardPermissoes
              fetchUtilizador={fetchFuncionariosUtilizadores}
              show={show}
              classes={classes}
            />
          </Col>
        </CardBody>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
}

export default DetalhesUtilizador;

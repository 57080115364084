/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles, styled, withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  IconButton,
  Collapse,
  Autocomplete,
  Typography,
} from '@mui/material';
import orange from '@mui/material/colors/orange';
import Notification from 'rc-notification';
import red from '@mui/material/colors/red';
import { MdInfoOutline } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GuardarTempos from '../../../../../../../redux/actions/TemposActions/GuardarTemposAction';
import ExcluirFuncionario from '../../../../../../../redux/actions/TemposActions/ExcluirFuncionarioAction';
import ModalDelete from './Components/modalDelete';
import { FullWideNotification } from '../../../../../../../shared/components/Notification';

import HorasButtons from './Components/HorasButtons';
import AusenciaTooltip from './Components/Tooltip/NormalToolttip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    '& > *': {
      marginTop: '25px',
      marginLeft: '10px',
      width: '4ch',
    },
  },
  buttons: {
    color: '#E21450',
    minWidth: '50px',
    height: '40px',
    marginRight: '8px !important',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  buttonsMobile: {
    color: '#E21450',
    minWidth: '30px',
    height: '30px',
    marginRight: '8px !important',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnDelete: {
    color: '#E21450',
    minWidth: '50px',
    height: '40px',
    marginRight: '8px !important',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnDeleteMobile: {
    color: '#E21450',
    minWidth: '40px',
    height: '30px',
    marginRight: '8px !important',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnDeleteDisabled: {
    minWidth: '50px',
    height: '40px',
    marginRight: '8px !important',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: '#ebebeb',
    fontSize: '18px',
  },
  btnDeleteDisabledMobile: {
    minWidth: '40px',
    height: '30px',
    marginRight: '8px !important',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: '#ebebeb',
    fontSize: '18px',
  },
  horasBorder: {
    width: '50px',
    height: '40px',
    textAlign: 'center',
    border: '1px solid #000',
    backgroundColor: '#ebebeb',
    borderRadius: '5px',
    padding: '8px',
  },
  horasBorderMobile: {
    width: '42px',
    height: '30px',
    textAlign: 'center',
    border: '1px solid #000',
    backgroundColor: '#ebebeb',
    borderRadius: '5px',
    padding: '4px',
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#E21450',
    '&$checked': {
      color: '#E21450',
    },
    background: 'transparent !important',
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

let notificationTC = null;

function ListaRecursos({
  recurso,
  idObra,
  tiposfaltas,
  tiposperiodos,
  tiposatrasos,
  useWindowDimensions,
  fetchFuncNaoAss,
  theme,
}) {
  const { width } = useWindowDimensions();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();
  const [horas, setHoras] = useState(recurso.qtd);
  const [checkbox, setCheckbox] = useState({
    falta: false,
  });
  const [valueTipoAtrasoAutoComplete, setValueTipoAtrasoAutoComplete] = useState({
    id: 0,
    descricao: '',
  });

  const [valueTipoPeriodoAutoComplete, setValueTipoPeriodoAutoComplete] = useState({
    id: 0,
    descricao: '',
  });
  const [valueObservacoesTextField, setValueObservacoesTextField] = useState('');

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const recursosAssociados = useSelector((state) => state.tempos.recursosAssociados);

  // console.log('recursosAssociados', recursosAssociados);
  // console.log('recurso', recurso);

  useEffect(() => {
    setHoras(recurso.qtd);
    const tipoAtrasoSelected = tiposatrasos.find((atraso) => atraso.id === recurso.tipoatrasos_id);
    const tipoPeriodoSelected = tiposperiodos.find(
      (periodo) => periodo.id === recurso.tipoperiodos_id,
    );

    if (tipoAtrasoSelected) {
      setValueTipoAtrasoAutoComplete(tipoAtrasoSelected);
    } else {
      setValueTipoAtrasoAutoComplete(null);
    }
    if (tipoPeriodoSelected) {
      setValueTipoPeriodoAutoComplete(tipoPeriodoSelected);
    } else {
      setValueTipoPeriodoAutoComplete(null);
    }
    setValueObservacoesTextField(recurso.observacoes || null);
  }, [recurso]);

  const handleData = (currentHoras) => {
    recurso.qtd = currentHoras;
    setHoras(currentHoras);
    console.log(currentHoras);

    const position = recursosAssociados.findIndex(
      (r) => r.obras_id === recurso.obras_id && r.recursos_id === recurso.recursos_id,
    );
    recursosAssociados[position].qtd = currentHoras;
    dispatch(GuardarTempos(recursosAssociados));
  };

  // ------------------------------ Handle Falta--------------------------//

  const handleFaltas = (falta) => {
    setCheckbox(falta);

    const position = recursosAssociados.findIndex(
      (r) => r.obras_id === recurso.obras_id && r.recursos_id === recurso.recursos_id,
    );
    recursosAssociados[position].faltou = falta;
    dispatch(GuardarTempos(recursosAssociados));
  };

  // --------------------------------------------------------------------//

  // ------------------------ Handle Autocompletes----------------------//
  const handleChangeAtrasos = (value) => {
    setValueTipoAtrasoAutoComplete(value);

    const position = recursosAssociados.findIndex(
      (r) => r.obras_id === recurso.obras_id && r.recursos_id === recurso.recursos_id,
    );
    if (value) {
      recursosAssociados[position].tipoatrasos_id = value.id;
    } else {
      recursosAssociados[position].tipoatrasos_id = null;
    }
    dispatch(GuardarTempos(recursosAssociados));
  };

  const handleChangePeriodo = (value) => {
    setValueTipoPeriodoAutoComplete(value);

    const position = recursosAssociados.findIndex(
      (r) => r.obras_id === recurso.obras_id && r.recursos_id === recurso.recursos_id,
    );

    if (value) {
      recursosAssociados[position].tipoperiodos_id = value.id;
    } else {
      recursosAssociados[position].tipoperiodos_id = null;
    }
    dispatch(GuardarTempos(recursosAssociados));
  };
  // --------------------------------------------------------------------//

  const handleChangeObservacoes = (e) => {
    setValueObservacoesTextField(e.target.value);

    const position = recursosAssociados.findIndex(
      (r) => r.obras_id === recurso.obras_id && r.recursos_id === recurso.recursos_id,
    );

    recursosAssociados[position].observacoes = e.target.value;
    dispatch(GuardarTempos(recursosAssociados));
  };

  // ---------- Notification Sytem ------------------- //
  useEffect(() => {
    Notification.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ---------- Modal ------------------- //

  const [openDelete, setOpenDelete] = useState(false);

  const handleOpen = (btn, e, id) => {
    e.preventDefault();
    // setErrorsTipoFalta({});
    setLoading(false);
    if (btn == 'delete') {
      setOpenDelete(true);
      // setAusenciaId(id);
      // resetAusencia();

      // setTipoFaltaNome(tableMeta.rowData[0]);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    // setErrorsTipoFalta({});
    if (btn == 'delete') {
      setOpenDelete(false);
    }
  };

  return (
    <>
      <ModalDelete
        obra={idObra}
        funcionario={recurso}
        dispatch={dispatch}
        ExcluirFuncionario={ExcluirFuncionario}
        recursoHoras={horas}
        fetchFuncNaoAss={fetchFuncNaoAss}
        openDelete={openDelete}
        handleClose={handleClose}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      {width <= 1750 && width >= 1250 ? (
        <Box
          sx={{
            width: '48%',
            mx: '1%',
            my: 1,
            padding: 2,
            maxHeight: '120px',
            border: '1px solid #000',
            borderRadius: '5px',
          }}
        >
          {/* <Container> */}
          <div style={{ width: '100%' }}>
            <div>
              {/* Delete e nome */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                  {recurso.recursos_id} - {recurso.nome2 ? recurso.nome2 : recurso.nome}
                </Typography>

                {/* ------------------------- Ausencia ----------------------- */}
                {recurso && (recurso.ausencia || recurso.notificacaoHorasNoutraObra) && (
                  <AusenciaTooltip style={{ opacity: '1 !important' }} recurso={recurso} />
                )}
                {/* ---------------------------------------------------------- */}
              </Box>
              <Box sx={{ display: 'flex', mb: 3 }}>
                <Button
                  disabled={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? true
                      : false
                  }
                  // color="danger"
                  onClick={(e) => handleOpen('delete', e)}
                  size="sm"
                  className={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? classes.btnDeleteDisabled
                      : classes.btnDelete
                  }
                >
                  <FiTrash2 size={20} />
                </Button>

                {/* Horas, Butões e atrasos */}
                <div
                  style={{
                    display: 'flex',
                    width: '70%',
                  }}
                >
                  <div className={classes.horasBorder}>
                    <h4>{horas}h</h4>
                  </div>
                  <HorasButtons recurso={recurso} classes={classes} handleData={handleData} />
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={recurso && recurso.faltou}
                        onChange={() => {
                          setCheckbox(!recurso.faltou);
                          handleFaltas(!recurso.faltou);
                        }}
                        name="falta"
                        disabled={
                          recurso &&
                          (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                        }
                      />
                    }
                    label="Falta"
                  />
                </Box>
                {/* <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore> */}
              </Box>
              {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ mt: 2.5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recurso && recurso.faltou}
                          onChange={() => {
                            setCheckbox(!recurso.faltou);
                            handleFaltas(!recurso.faltou);
                          }}
                          name="falta"
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                        />
                      }
                      label="Falta"
                    />
                  </Box>
                  <Box sx={{ width: '80%' }}>
                    {recurso && recurso.faltou == true ? (
                      <div style={{ width: '100%' }}>
                        <b>Tipo de Periodo</b>
                        <Autocomplete
                          id="tipoPeriodoId"
                          name="tipoPeriodoId"
                          noOptionsText="Sem dados"
                          onChange={(e, value) => handleChangePeriodo(value)}
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          value={valueTipoPeriodoAutoComplete}
                          options={tiposperiodos || [{ id: 0, descricao: '' }]}
                          getOptionLabel={(option) =>
                            option.descricao ? `${option.descricao} ` : ''
                          }
                          filterOptions={(options, state) => {
                            return options.filter(
                              (option) =>
                                option.id > 0 &&
                                `${option.descricao}`
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase()),
                            );
                          }}
                          getOptionSelected={(option, value) => {
                            // if (!value.id) return true;

                            return value.id === option.id;
                          }}
                          autoComplete
                          includeInputInList
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              // required
                              // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                            />
                          )}
                        />
                      </div>
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ my: 2 }}>
                  <b>Tipo de Atraso</b>
                  <Autocomplete
                    id="tipoAtrasoId"
                    name="tipoAtrasoId"
                    noOptionsText="Sem dados"
                    onChange={(e, value) => handleChangeAtrasos(value)}
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    value={valueTipoAtrasoAutoComplete}
                    options={tiposatrasos || [{ id: 0, descricao: '' }]}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option.id > 0 &&
                          `${option.descricao}`
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    autoComplete
                    includeInputInList
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    disabled={
                      recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        // required
                        // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <b>Observações</b>
                  <TextField
                    name="observacoes"
                    variant="outlined"
                    style={{ width: '100%' }}
                    size="small"
                    disabled={
                      recurso && (recurso.validadoAD || recurso.validadoRH || recurso.validadoCD)
                    }
                    value={valueObservacoesTextField || ''}
                    onChange={handleChangeObservacoes}
                  />
                </Box>
              </Collapse> */}
            </div>
          </div>
        </Box>
      ) : null}
      {width < 1250 && width >= 770 ? (
        <Box
          sx={{
            width: '100%',
            mx: '1%',
            my: 1,
            padding: 2,
            maxHeight: '120px',
            border: '1px solid #000',
            borderRadius: '5px',
          }}
        >
          {/* <Container> */}
          <div style={{ width: '100%' }}>
            <div>
              {/* Delete e nome */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                  {recurso.recursos_id} - {recurso.nome2 ? recurso.nome2 : recurso.nome}
                </Typography>

                {/* ------------------------- Ausencia ----------------------- */}
                {recurso && (recurso.ausencia || recurso.notificacaoHorasNoutraObra) && (
                  <AusenciaTooltip style={{ opacity: '1 !important' }} recurso={recurso} />
                )}
                {/* ---------------------------------------------------------- */}
              </Box>
              <Box sx={{ display: 'flex', mb: 3 }}>
                <Button
                  disabled={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? true
                      : false
                  }
                  // color="danger"
                  onClick={(e) => handleOpen('delete', e)}
                  size="sm"
                  className={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? classes.btnDeleteDisabled
                      : classes.btnDelete
                  }
                >
                  <FiTrash2 size={20} />
                </Button>

                {/* Horas, Butões e atrasos */}
                <div
                  style={{
                    display: 'flex',
                    width: '70%',
                  }}
                >
                  <div className={classes.horasBorder}>
                    <h4>{horas}h</h4>
                  </div>
                  <HorasButtons recurso={recurso} classes={classes} handleData={handleData} />
                  <div />
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={recurso && recurso.faltou}
                        onChange={() => {
                          setCheckbox(!recurso.faltou);
                          handleFaltas(!recurso.faltou);
                        }}
                        name="falta"
                        disabled={
                          recurso &&
                          (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                        }
                      />
                    }
                    label="Falta"
                  />
                </Box>
                {/* <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore> */}
              </Box>
              {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ mt: 2.5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recurso && recurso.faltou}
                          onChange={() => {
                            setCheckbox(!recurso.faltou);
                            handleFaltas(!recurso.faltou);
                          }}
                          name="falta"
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                        />
                      }
                      label="Falta"
                    />
                  </Box>
                  <Box sx={{ width: '80%' }}>
                    {recurso && recurso.faltou == true ? (
                      <div style={{ width: '100%' }}>
                        <b>Tipo de Periodo</b>
                        <Autocomplete
                          id="tipoPeriodoId"
                          name="tipoPeriodoId"
                          noOptionsText="Sem dados"
                          onChange={(e, value) => handleChangePeriodo(value)}
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          value={valueTipoPeriodoAutoComplete}
                          options={tiposperiodos || [{ id: 0, descricao: '' }]}
                          getOptionLabel={(option) =>
                            option.descricao ? `${option.descricao} ` : ''
                          }
                          filterOptions={(options, state) => {
                            return options.filter(
                              (option) =>
                                option.id > 0 &&
                                `${option.descricao}`
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase()),
                            );
                          }}
                          getOptionSelected={(option, value) => {
                            // if (!value.id) return true;

                            return value.id === option.id;
                          }}
                          autoComplete
                          includeInputInList
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              // required
                              // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                            />
                          )}
                        />
                      </div>
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ my: 2 }}>
                  <b>Tipo de Atraso</b>
                  <Autocomplete
                    id="tipoAtrasoId"
                    name="tipoAtrasoId"
                    noOptionsText="Sem dados"
                    onChange={(e, value) => handleChangeAtrasos(value)}
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    value={valueTipoAtrasoAutoComplete}
                    options={tiposatrasos || [{ id: 0, descricao: '' }]}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option.id > 0 &&
                          `${option.descricao}`
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    autoComplete
                    includeInputInList
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    disabled={
                      recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        // required
                        // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <b>Observações</b>
                  <TextField
                    name="observacoes"
                    variant="outlined"
                    style={{ width: '100%' }}
                    size="small"
                    disabled={
                      recurso && (recurso.validadoAD || recurso.validadoRH || recurso.validadoCD)
                    }
                    value={valueObservacoesTextField || ''}
                    onChange={handleChangeObservacoes}
                  />
                </Box>
              </Collapse> */}
            </div>
          </div>
        </Box>
      ) : null}
      {width > 1750 ? (
        <Box
          sx={{
            width: '31%',
            mx: '1%',
            my: 1,
            padding: 2,
            maxHeight: '120px',
            border: '1px solid #000',
            borderRadius: '5px',
          }}
        >
          {/* <Container> */}
          <div style={{ width: '100%' }}>
            <div>
              {/* Delete e nome */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                  {recurso.recursos_id} - {recurso.nome2 ? recurso.nome2 : recurso.nome}
                </Typography>

                {/* ------------------------- Ausencia ----------------------- */}
                {recurso && (recurso.ausencia || recurso.notificacaoHorasNoutraObra) && (
                  <AusenciaTooltip style={{ opacity: '1 !important' }} recurso={recurso} />
                )}
                {/* ---------------------------------------------------------- */}
              </Box>
              <Box sx={{ display: 'flex', mb: 3 }}>
                <Button
                  disabled={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? true
                      : false
                  }
                  // color="danger"
                  onClick={(e) => handleOpen('delete', e)}
                  size="sm"
                  className={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? classes.btnDeleteDisabled
                      : classes.btnDelete
                  }
                >
                  <FiTrash2 size={20} />
                </Button>

                {/* Horas, Butões e atrasos */}
                <Box
                  sx={{
                    display: 'flex',
                    width: '70%',
                  }}
                >
                  <Box className={classes.horasBorder}>
                    <h4>{horas}h</h4>
                  </Box>
                  <HorasButtons recurso={recurso} classes={classes} handleData={handleData} />
                  <div />
                </Box>
                <Box sx={{ display: 'flex', width: '15%' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={recurso && recurso.faltou}
                        onChange={() => {
                          setCheckbox(!recurso.faltou);
                          handleFaltas(!recurso.faltou);
                        }}
                        name="falta"
                        disabled={
                          recurso &&
                          (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                        }
                      />
                    }
                    label="Falta"
                  />
                </Box>
                {/* <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore> */}
              </Box>

              {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ mt: 2.5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recurso && recurso.faltou}
                          onChange={() => {
                            setCheckbox(!recurso.faltou);
                            handleFaltas(!recurso.faltou);
                          }}
                          name="falta"
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                        />
                      }
                      label="Falta"
                    />
                  </Box>
                  <Box sx={{ width: '80%' }}>
                    {recurso && recurso.faltou == true ? (
                      <div style={{ width: '100%' }}>
                        <b>Tipo de Periodo</b>
                        <Autocomplete
                          id="tipoPeriodoId"
                          name="tipoPeriodoId"
                          noOptionsText="Sem dados"
                          onChange={(e, value) => handleChangePeriodo(value)}
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          value={valueTipoPeriodoAutoComplete}
                          options={tiposperiodos || [{ id: 0, descricao: '' }]}
                          getOptionLabel={(option) =>
                            option.descricao ? `${option.descricao} ` : ''
                          }
                          filterOptions={(options, state) => {
                            return options.filter(
                              (option) =>
                                option.id > 0 &&
                                `${option.descricao}`
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase()),
                            );
                          }}
                          getOptionSelected={(option, value) => {
                            // if (!value.id) return true;

                            return value.id === option.id;
                          }}
                          autoComplete
                          includeInputInList
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              // required
                              // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                            />
                          )}
                        />
                      </div>
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ my: 2 }}>
                  <b>Tipo de Atraso</b>
                  <Autocomplete
                    id="tipoAtrasoId"
                    name="tipoAtrasoId"
                    noOptionsText="Sem dados"
                    onChange={(e, value) => handleChangeAtrasos(value)}
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    value={valueTipoAtrasoAutoComplete}
                    options={tiposatrasos || [{ id: 0, descricao: '' }]}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option.id > 0 &&
                          `${option.descricao}`
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    autoComplete
                    includeInputInList
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    disabled={
                      recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        // required
                        // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <b>Observações</b>
                  <TextField
                    name="observacoes"
                    variant="outlined"
                    style={{ width: '100%' }}
                    size="small"
                    disabled={
                      recurso && (recurso.validadoAD || recurso.validadoRH || recurso.validadoCD)
                    }
                    value={valueObservacoesTextField || ''}
                    onChange={handleChangeObservacoes}
                  />
                </Box>
              </Collapse> */}
            </div>
          </div>
        </Box>
      ) : null}
      {width < 770 ? (
        <Box
          sx={{
            width: '100%',
            mx: '1%',
            my: 1,
            padding: 2,
            maxHeight: '120px',
            border: '1px solid #000',
            borderRadius: '5px',
          }}
        >
          {/* <Container> */}
          <div style={{ width: '100%' }}>
            <div>
              {/* Delete e nome */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <h4>
                  {recurso.recursos_id} - {recurso.nome2 ? recurso.nome2 : recurso.nome}
                </h4>

                {/* ------------------------- Ausencia ----------------------- */}
                {recurso && (recurso.ausencia || recurso.notificacaoHorasNoutraObra) && (
                  <AusenciaTooltip style={{ opacity: '1 !important' }} recurso={recurso} />
                )}
                {/* ---------------------------------------------------------- */}

                {/* <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore> */}
              </Box>
              <Box sx={{ display: 'flex', mb: 3 }}>
                <Button
                  disabled={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? true
                      : false
                  }
                  // color="danger"
                  onClick={(e) => handleOpen('delete', e)}
                  size="sm"
                  className={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? classes.btnDeleteDisabledMobile
                      : classes.btnDeleteMobile
                  }
                >
                  <FiTrash2 size={20} />
                </Button>
                <Box
                  sx={{
                    display: 'flex',
                    width: '70%',
                    mb: 3,
                  }}
                >
                  <Box className={classes.horasBorderMobile}>
                    <h4>{horas}h</h4>
                  </Box>
                  <HorasButtons
                    recurso={recurso}
                    classes={classes}
                    handleData={handleData}
                    width={width}
                  />
                  <div />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={recurso && recurso.faltou}
                        onChange={() => {
                          setCheckbox(!recurso.faltou);
                          handleFaltas(!recurso.faltou);
                        }}
                        name="falta"
                        disabled={
                          recurso &&
                          (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                        }
                      />
                    }
                    label="Falta"
                  />
                </Box>
              </Box>
              {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                {/* Horas, Butões e atrasos */}
              {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recurso && recurso.faltou}
                          onChange={() => {
                            setCheckbox(!recurso.faltou);
                            handleFaltas(!recurso.faltou);
                          }}
                          name="falta"
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                        />
                      }
                      label="Falta"
                    />
                  </Box>
                  <Box sx={{ width: '80%' }}>
                    {recurso && recurso.faltou == true ? (
                      <div style={{ width: '100%' }}>
                        <b>Tipo de Periodo</b>
                        <Autocomplete
                          id="tipoPeriodoId"
                          name="tipoPeriodoId"
                          noOptionsText="Sem dados"
                          onChange={(e, value) => handleChangePeriodo(value)}
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          value={valueTipoPeriodoAutoComplete}
                          options={tiposperiodos || [{ id: 0, descricao: '' }]}
                          getOptionLabel={(option) =>
                            option.descricao ? `${option.descricao} ` : ''
                          }
                          filterOptions={(options, state) => {
                            return options.filter(
                              (option) =>
                                option.id > 0 &&
                                `${option.descricao}`
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase()),
                            );
                          }}
                          getOptionSelected={(option, value) => {
                            // if (!value.id) return true;

                            return value.id === option.id;
                          }}
                          autoComplete
                          includeInputInList
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              // required
                              // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                            />
                          )}
                        />
                      </div>
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ my: 2 }}>
                  <b>Tipo de Atraso</b>
                  <Autocomplete
                    id="tipoAtrasoId"
                    name="tipoAtrasoId"
                    noOptionsText="Sem dados"
                    onChange={(e, value) => handleChangeAtrasos(value)}
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    value={valueTipoAtrasoAutoComplete}
                    options={tiposatrasos || [{ id: 0, descricao: '' }]}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option.id > 0 &&
                          `${option.descricao}`
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    autoComplete
                    includeInputInList
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    disabled={
                      recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        // required
                        // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <b>Observações</b>
                  <TextField
                    name="observacoes"
                    variant="outlined"
                    style={{ width: '100%' }}
                    size="small"
                    disabled={
                      recurso && (recurso.validadoAD || recurso.validadoRH || recurso.validadoCD)
                    }
                    value={valueObservacoesTextField || ''}
                    onChange={handleChangeObservacoes}
                  />
                </Box>
              </Collapse> */}
            </div>
          </div>
        </Box>
      ) : null}
      {width < 770 ? (
        <Box
          sx={{
            width: '100%',
            mx: '1%',
            my: 1,
            padding: 2,
            maxHeight: '120px',
            border: '1px solid #000',
            borderRadius: '5px',
          }}
        >
          {/* <Container> */}
          <div style={{ width: '100%' }}>
            <div>
              {/* Delete e nome */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <h4>
                  {recurso.recursos_id} - {recurso.nome2 ? recurso.nome2 : recurso.nome}
                </h4>

                {/* ------------------------- Ausencia ----------------------- */}
                {recurso && (recurso.ausencia || recurso.notificacaoHorasNoutraObra) && (
                  <AusenciaTooltip style={{ opacity: '1 !important' }} recurso={recurso} />
                )}
                {/* ---------------------------------------------------------- */}

                {/* <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore> */}
              </Box>
              <Box sx={{ display: 'flex', mb: 3 }}>
                <Button
                  disabled={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? true
                      : false
                  }
                  // color="danger"
                  onClick={(e) => handleOpen('delete', e)}
                  size="sm"
                  className={
                    !horas <= 0 || recurso.validadoCD || recurso.validadoAD || recurso.validadoRH
                      ? classes.btnDeleteDisabledMobile
                      : classes.btnDeleteMobile
                  }
                >
                  <FiTrash2 size={20} />
                </Button>
                <Box
                  sx={{
                    display: 'flex',
                    width: '70%',
                    mb: 3,
                  }}
                >
                  <Box className={classes.horasBorderMobile}>
                    <h4>{horas}h</h4>
                  </Box>
                  <HorasButtons
                    recurso={recurso}
                    classes={classes}
                    handleData={handleData}
                    width={width}
                  />
                  <div />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={recurso && recurso.faltou}
                        onChange={() => {
                          setCheckbox(!recurso.faltou);
                          handleFaltas(!recurso.faltou);
                        }}
                        name="falta"
                        disabled={
                          recurso &&
                          (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                        }
                      />
                    }
                    label="Falta"
                  />
                </Box>
              </Box>
              {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
              {/* Horas, Butões e atrasos */}
              {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recurso && recurso.faltou}
                          onChange={() => {
                            setCheckbox(!recurso.faltou);
                            handleFaltas(!recurso.faltou);
                          }}
                          name="falta"
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                        />
                      }
                      label="Falta"
                    />
                  </Box>
                  <Box sx={{ width: '80%' }}>
                    {recurso && recurso.faltou == true ? (
                      <div style={{ width: '100%' }}>
                        <Autocomplete
                          id="tipoPeriodoId"
                          name="tipoPeriodoId"
                          noOptionsText="Sem dados"
                          onChange={(e, value) => handleChangePeriodo(value)}
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          value={valueTipoPeriodoAutoComplete}
                          options={tiposperiodos || [{ id: 0, descricao: '' }]}
                          getOptionLabel={(option) =>
                            option.descricao ? `${option.descricao} ` : ''
                          }
                          filterOptions={(options, state) => {
                            return options.filter(
                              (option) =>
                                option.id > 0 &&
                                `${option.descricao}`
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase()),
                            );
                          }}
                          getOptionSelected={(option, value) => {
                            // if (!value.id) return true;

                            return value.id === option.id;
                          }}
                          autoComplete
                          includeInputInList
                          disabled={
                            recurso &&
                            (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Período"
                              placeholder="Tipo de Período"
                              variant="outlined"
                              size="small"
                              // required
                              // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                            />
                          )}
                        />
                      </div>
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Autocomplete
                    id="tipoAtrasoId"
                    name="tipoAtrasoId"
                    noOptionsText="Sem dados"
                    onChange={(e, value) => handleChangeAtrasos(value)}
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    value={valueTipoAtrasoAutoComplete}
                    options={tiposatrasos || [{ id: 0, descricao: '' }]}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option.id > 0 &&
                          `${option.descricao}`
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    autoComplete
                    includeInputInList
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    disabled={
                      recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Atrasos"
                        placeholder="Tipo de Atrasos"
                        variant="outlined"
                        size="small"
                        // required
                        // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    label="Observações"
                    placeholder="Observações"
                    name="observacoes"
                    variant="outlined"
                    style={{ width: '100%' }}
                    size="small"
                    disabled={
                      recurso && (recurso.validadoAD || recurso.validadoRH || recurso.validadoCD)
                    }
                    value={valueObservacoesTextField || ''}
                    onChange={handleChangeObservacoes}
                  />
                </Box>
              </Collapse> */}
            </div>
          </div>
        </Box>
      ) : null}
    </>
  );
}

export default ListaRecursos;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable spaced-comment */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ptLocale from 'date-fns/locale/pt';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

//BREADCRUMBS
import BreadLogsHoras from '../../../App/Breadcrumbs/Administracao/LogsHoras/BreadLogsHoras';

//ACTIONS
import GetLogsHoras from '../../../../redux/actions/LogsActions/GetLogsHorasAction';
import GetObrasValidacao from '../../../../redux/actions/ValidacaoActions/getObrasValidacao';
import GetFuncionariosValidacao from '../../../../redux/actions/ValidacaoActions/getFuncionariosValidacao';

const useStyles = makeStyles(() => ({
  btnConsultar: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function LogsHoras() {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [dateSelected, setDateSelected] = useState(null);
  const [obra, setObra] = useState();
  const [funcionario, setFuncionario] = useState();
  const dispatch = useDispatch();

  const obrasValidacao = useSelector((state) => state.validacao.obrasValidacao);
  const funcionariosValidacao = useSelector((state) => state.validacao.funcionariosValidacao);
  // const data = useSelector((state) => state.logs);

  const handleDateSelectedChange = (date) => {
    setDateSelected(date);
    //dispatch({ type: 'GET_RELATORIO_COLABORADORES_ATIVOS', payload: [] });
  };

  const handleChangeObra = (e, value) => {
    e.preventDefault();

    setObra(value);
  };
  const handleChangeFuncionario = (e, value) => {
    e.preventDefault();

    setFuncionario(value);
  };

  const obraId = obra && obra.id;
  const funcionarioId = funcionario && funcionario.id;

  const handleConsultar = () => {
    const selectedData = dateSelected && format(new Date(dateSelected), 'yyyy-MM-dd');
    dispatch(GetLogsHoras(selectedData, obraId, funcionarioId));
  };

  const fetchObras = () => {
    dispatch(GetObrasValidacao());
  };
  const fetchFuncionarios = () => {
    dispatch(GetFuncionariosValidacao());
  };
  const fetchLogs = () => {
    dispatch(GetLogsHoras());
  };

  useEffect(() => {
    fetchObras();
  }, []);
  useEffect(() => {
    fetchFuncionarios();
  }, []);
  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <BreadLogsHoras />
          <h4
            style={{
              fontSize: '22px',
              width: '100%',
              marginTop: 20,
              marginBottom: 20,
            }}
            className="text-underline"
          >
            Logs Horas
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Col md={12} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: width < 1100 ? 'column' : 'row',
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: width < 1100 ? '100%' : '20%',
                        mr: '2%',
                      }}
                    >
                      <b>Data Inicio</b>
                      <DatePicker
                        style={{ width: '100%' }}
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        okLabel="ok"
                        cancelLabel="cancelar"
                        invalidDateMessage="Data Inválida"
                        value={dateSelected}
                        onChange={handleDateSelectedChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'dd/mm/aaaa',
                            }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </Box>
                  </LocalizationProvider>
                  <Box
                    sx={{
                      width: width < 1100 ? '100%' : '20%',
                      mr: '2%',
                      mb: width < 1100 ? 2 : 0,
                    }}
                  >
                    <b>Obra</b>
                    <Autocomplete
                      name="obra"
                      noOptionsText="Sem dados"
                      clearText="Limpar"
                      size="small"
                      openText="Abrir"
                      sx={{ mt: -2 }}
                      closeText="Fechar"
                      onChange={(e, value) => handleChangeObra(e, value)}
                      options={obrasValidacao}
                      getOptionLabel={(option) =>
                        `${option.recursos_id || option.id} - ${option.descricao} `
                      }
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          // required={user.recursosId.length === 0}
                          // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: width < 1100 ? '100%' : '20%',
                      mr: '2%',
                      mb: width < 1100 ? 2 : 0,
                    }}
                  >
                    <b>Funcionário</b>
                    <Autocomplete
                      name="funcionario"
                      noOptionsText="Sem dados"
                      clearText="Limpar"
                      size="small"
                      openText="Abrir"
                      closeText="Fechar"
                      sx={{ mt: -2 }}
                      onChange={(e, value) => handleChangeFuncionario(e, value)}
                      options={funcionariosValidacao}
                      getOptionLabel={(option) =>
                        `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
                      }
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          // required={user.recursosId.length === 0}
                          // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: width < 1100 ? '100px' : '7%',
                      mr: '2%',
                      mb: width < 1100 ? 2 : 0,
                      mt: width < 1100 ? 0 : 2.5,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleConsultar}
                      className={classes.btnConsultar}
                      sx={{ width: '100%' }}
                    >
                      Filtrar
                    </Button>
                  </Box>
                </Box>
              </Col>
              <Row>
                <Col>
                  <Col md={12} sm={12}>
                    <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                      <h4 style={{ width: '100%' }} className="text-underline-title">
                        Consulta
                      </h4>
                    </Box>
                  </Col>
                  <Col md={12} sm={12}>
                    {/* <MUIDataTable description="Nada para mostrar" data={data} columns={columns} /> */}
                  </Col>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default LogsHoras;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable object-shorthand */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import { FiEdit, FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi';

// MODALS
// import ModalDelete from './modals/modalDelete';
// import ModalRedefinirSenha from './modals/modalRedefinirSenha';
// import ModalEditarUtilizador from './modals/modalEditarUtilizador';

function CardFerias({ ferias, handleOpen }) {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  // ====================== Search Text ====================== //

  const [searchText, setSearchText] = useState();

  // ========================================================= //

  const columns = [
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'ano',
      label: 'Ano',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'diasFeriasTotal',
      label: 'Total de Férias',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'diasFeriasManuais',
      label: 'Férias Utilizadas',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'utilizadorNome',
      label: 'Utilizador',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposfaltas">
              <Button
                onClick={(e) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value, tableMeta)
                }
              >
                <FiEdit size={30} strokeWidth={1} color="#000" />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
              >
                <FiTrash2 size={30} strokeWidth={1} color="#000" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    print: false,
    search: false,
    searchText: searchText,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    // customToolbar: () => {
    //   return <CustomToolbar handleOpen={handleOpen} />;
    // },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'Limpar',
      },
    },
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box>
          <b>Pesquisar</b>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            name="search"
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <FiSearch />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ ml: 2 }}>
          <Button
            sx={{ border: '1px solid #000', minWidth: '45px', mt: 2.5 }}
            onClick={(e) => handleOpen('add', e)}
          >
            <FiPlus size={25} strokeWidth={1.5} />
          </Button>
        </Box>
      </Box>
      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Registos
        </h4>
      </Box>
      <MUIDataTable
        title=""
        description="Nada para mostrar"
        data={ferias}
        columns={columns}
        options={options}
      />
    </>
  );
}

export default CardFerias;

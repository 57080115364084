/* eslint-disable default-param-last */
import { tipoFaltaInitialState } from '../InitialState/tipoFaltas';

const tiposFaltasReducer = (state = tipoFaltaInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_TIPOFALTAS':
      return {
        ...state,
        tiposFaltas: payload,
      };
    case 'GET_TIPOFALTAS_DROPDOWN':
      return {
        ...state,
        dropdownTiposFaltas: payload,
      };
    case 'GET_TIPOFALTA':
      return {
        ...state,
        tipoFalta: payload,
      };
    case 'POST_TIPOFALTA':
      return {
        ...state,
        novoTipoFalta: payload,
      };
    case 'PUT_TIPOFALTA':
      return {
        ...state,
        tipoFaltaEditado: payload,
      };
    case 'DELETE_TIPOFALTA':
      return {
        ...state,
        tipoFaltaRemovido: payload,
      };
    case 'CLEAR_DATA_TIPOFALTA':
      return {
        ...state,
        tipoFalta: [],
      };

    default:
      return state;
  }
};

export default tiposFaltasReducer;

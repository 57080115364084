/* eslint-disable default-param-last */
import { funcionariosInitialState } from '../InitialState/funcionarios';

const funcionariosReducer = (state = funcionariosInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_TODOS_FUNCIONARIOS':
      return {
        ...state,
        todosFuncionarios: payload,
      };
    case 'PUT_FUNCIONARIO':
      return {
        ...state,
        funcionarioEditado: payload,
      };
    case 'GET_FUNCIONARIO_CATEGORIAS':
      return {
        ...state,
        categorias: payload,
      };
    case 'GET_FUNCIONARIO':
      return {
        ...state,
        funcionario: payload,
      };

    // Horas
    case 'GET_FUNCIONARIO_HORAS':
      return {
        ...state,
        horas: payload,
      };
    case 'GET_FUNCIONARIO_REGISTO_HORAS':
      return {
        ...state,
        registoHoras: payload,
      };
    case 'PUT_FUNCIONARIO_REGISTO_HORAS':
      return {
        ...state,
        registoHorasEditadas: payload,
      };
    case 'DELETE_FUNCIONARIO_REGISTO_HORAS':
      return {
        ...state,
        ausenciaRemovida: payload,
      };
    case 'CLEAR_FUNCIONARIO_REGISTO_HORAS':
      return {
        ...state,
        registoHoras: payload,
      };

    // Ausencias
    case 'GET_FUNCIONARIO_AUSENCIAS':
      return {
        ...state,
        ausencias: payload,
      };
    case 'GET_FUNCIONARIO_AUSENCIA':
      return {
        ...state,
        ausencia: payload,
      };
    case 'DELETE_AUSENCIA':
      return {
        ...state,
        ausenciaRemovida: payload,
      };
    case 'PUT_AUSENCIA':
      return {
        ...state,
        ausenciaEditada: payload,
      };
    case 'CLEAR_AUSENCIA':
      return {
        ...state,
        ausencia: payload,
      };

    // Observacoes
    case 'GET_FUNCIONARIO_OBSERVACOES':
      return {
        ...state,
        observacoes: payload,
      };
    case 'DELETE_FUNCIONARIO_OBSERVACAO':
      return {
        ...state,
        observacaoRemovida: payload,
      };
    case 'PUT_FUNCIONARIO_OBSERVACAO':
      return {
        ...state,
        observacaoEditada: payload,
      };

    // Ferias
    case 'GET_FUNCIONARIO_FERIAS':
      return {
        ...state,
        ferias: payload,
      };
    case 'DELETE_FUNCIONARIO_FERIAS':
      return {
        ...state,
        feriasRemovidas: payload,
      };
    case 'PUT_FUNCIONARIO_FERIAS':
      return {
        ...state,
        feriasEditadas: payload,
      };

    default:
      return state;
  }
};

export default funcionariosReducer;

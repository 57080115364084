/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

function Form({ recurso, onSubmit, setRecurso, validateOnChange = true, errors, validation }) {
  const funcionarios = useSelector((state) => state.tempos.funcionariosNaoAssociados);

  const handleChangeFuncs = async (e, value) => {
    if (value) {
      await setRecurso({
        ...recurso,
        recursosId: value,
      });
      if (validateOnChange) validation({ recursosId: value });
    } else {
      await setRecurso({
        ...recurso,
        recursosId: '',
      });
      if (validateOnChange) validation({ recursosId: '' });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      {/* <div>
            <Autocomplete
              name="recursosId"
              noOptionsText="Sem dados"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              onChange={(e, value) => handleChangeRecursos(e, value)}
              options={funcionarios}
              getOptionLabel={(option) =>
                `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
              }
              id="auto-complete"
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Funcionário"
                  margin="normal"
                  // required={user.recursosId.length === 0}
                  {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                />
              )}
            />
          </div> */}
      <b style={{ marginTop: '20px' }}>Funcionários</b>
      <Autocomplete
        limitTags={10}
        multiple
        size="small"
        id="tags-outlined"
        name="funcionarios"
        noOptionsText="Sem dados"
        clearText="Limpar"
        openText="Abrir"
        closeText="Fechar"
        onChange={(e, value) => handleChangeFuncs(e, value)}
        options={funcionarios || []}
        getOptionLabel={
          (option) => {
            if (option && option.id) {
              if (option.nome && option.nome2) {
                return `${option.id} - ${option.nome} - ${option.nome2} `;
              }
              if (option.nome) {
                return `${option.id} - ${option.nome}`;
              }
              if (option.nome2) {
                return `${option.id} - ${option.nome2}`;
              }
            }
            return '';
          }
          // `${option.id} - ${option.nome} - ${option.nome2}`
        }
        isOptionEqualToValue={(option, value) => value.id === option.id}
        filterSelectedOptions
        // value={(data && data.funcionarios) || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            // variant="outlined"
            // required={funcs.length === 0}
            {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
          />
        )}
      />
    </form>
  );
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(Form);

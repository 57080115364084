import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from "@mui/material/Link";
import { Link } from 'react-router-dom';

function BreadLogsHoras() {
  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumb"
      style={{ fontSize: '0.9em', marginBottom: '10px', marginTop: '12px' }}
    >
      <Link to="/app">Inicio</Link>
      <Typography style={{ fontSize: '1.2em' }} color="textPrimary">
        Logs Horas
      </Typography>
    </Breadcrumbs>
  );
}
export default BreadLogsHoras;

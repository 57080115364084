/* eslint-disable default-param-last */
import { obrasInitialState } from '../InitialState/obras';

const obrasReducer = (state = obrasInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_TODAS_OBRAS':
      return {
        ...state,
        obras: payload,
      };
    case 'GET_INFO_OBRAS':
      return {
        ...state,
        infoObra: payload,
      };
    case 'GET_HISTORICO_MENSAL':
      return {
        ...state,
        historicoMensal: payload,
      };
    case 'GET_RECURSOS_DEFINIDOS':
      return {
        ...state,
        recursosDefinidos: payload,
      };
    case 'PUT_COR_OBRA':
      return {
        ...state,
        obras: payload,
      };
    case 'GET_CORES_OBRAS':
      return {
        ...state,
        cores: payload,
      };
    default:
      return state;
  }
};

export default obrasReducer;

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { addYears, startOfMonth, endOfMonth, format } from 'date-fns';
import { withStyles } from '@mui/styles';
import {
  Button,
  FormControlLabel,
  TextField,
  Checkbox,
  Autocomplete,
  InputAdornment,
  IconButton,
  Box,
} from '@mui/material';
import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptLocale from 'date-fns/locale/pt';
import { FiCheck, FiSearch, FiX } from 'react-icons/fi';

import TodasAsObrasRelatorio from '../../../../../../redux/actions/RelatoriosActions/TodasAsObrasRelatorioAction';
import TodosOsFuncionariosRelatorio from '../../../../../../redux/actions/RelatoriosActions/TodosOsFuncionariosRelatorio';
// import RelatorioObraMensal from './RelatorioObraMensal';
import TabelaRelatorio from './TabelaRelatorio';
import RelatorioObra from '../../../../../../redux/actions/RelatoriosActions/RelatorioMensalHoras/RelatorioMensalHorasAction';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function CardRelatorio({ classes }) {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const { width } = useWindowDimensions();
  const [funcs, setFuncs] = useState();
  const [selectedObra, setSelectedObra] = useState();
  const [btnObterDados, setBtnObterDados] = useState(false);
  const [ordenacao, setOrdenacao] = React.useState(false);
  const [mostrarValor, setMostrarValor] = useState(true);
  const [mostrarVazio, setMostrarVazio] = useState(false);
  const dispatch = useDispatch();

  // ====================== Search Text ====================== //

  const [searchText, setSearchText] = useState();

  // ========================================================= //

  const handleMonthChange = (date) => {
    setSelectedMonth(date);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_MENSAL_HORAS', payload: [] });
  };

  const handleChangeObra = (e, value) => {
    e.preventDefault();
    setSelectedObra(value);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_MENSAL_HORAS', payload: [] });
  };

  const handleChangeFuncs = (e, value) => {
    setFuncs(value);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_MENSAL_HORAS', payload: [] });
  };
  const dataInicio = format(startOfMonth(new Date(selectedMonth)), 'yyyy-MM-dd');
  // console.log(dataInicio);
  const dataFim = format(endOfMonth(new Date(selectedMonth)), 'yyyy-MM-dd');

  // Ordenação da tabela

  const handleChangeOrdenacao = () => {
    setOrdenacao(!ordenacao);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_MENSAL_HORAS', payload: [] });
  };

  const handleChangeMostrarValor = () => {
    setMostrarValor(!mostrarValor);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_MENSAL_HORAS', payload: [] });
  };

  const handleChangeMostrarVazio = () => {
    setMostrarVazio(!mostrarVazio);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_MENSAL_HORAS', payload: [] });
  };

  // Pedido de dados

  const handleSend = () => {
    // e.preventDefault();
    // console.log(ordenacao);
    let ordem = null;
    if (ordenacao == true) {
      ordem = 1;
    } else {
      ordem = 0;
    }
    dispatch(RelatorioObra(dataInicio, dataFim, funcs, selectedObra, ordem));
    setBtnObterDados(true);
  };

  const obras = useSelector((state) => state.relatorios.todasAsObrasRelatorio);
  const funcionarios = useSelector((state) => state.relatorios.todosOsFuncionariosRelatorio);

  const fetchObras = () => {
    dispatch(TodasAsObrasRelatorio());
  };
  const fetchFuncionarios = () => {
    dispatch(TodosOsFuncionariosRelatorio());
  };

  useEffect(() => {
    fetchObras();
  }, []);
  useEffect(() => {
    setBtnObterDados(false);
  }, []);
  useEffect(() => {
    fetchFuncionarios();
  }, []);
  useEffect(() => {
    dispatch({ type: 'GET_RELATORIO_MENSAL_HORAS', payload: [] });
  }, []);
  // useEffect(() => handleSend(), []);

  const user = useSelector((state) => state.user);

  const valorHora = user && user.permissoes && user.permissoes.includes('MOSTRAR_VALOR_HORA');

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Col md={12} sm={12}>
                <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                  <h4
                    style={{
                      width: '100%',
                    }}
                    className="text-underline-title"
                  >
                    Mensal de Horas
                  </h4>
                </Box>
                {/* <Container> */}
                <Box sx={{ display: 'flex', flexDirection: width < 1100 ? 'column' : 'row' }}>
                  <Box
                    sx={{
                      width: width < 1100 ? '50%' : '15%',
                      mr: width < 1100 ? 0 : '2%',
                      mb: width < 1100 ? 2 : 0,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                      <b>Escolha o mês</b>
                      <DesktopDatePicker
                        maxDate={addYears(new Date(), 1)}
                        minDate={addYears(new Date(), -1)}
                        style={{ width: '100%' }}
                        margin="normal"
                        views={['month', 'year']}
                        okLabel="ok"
                        cancelLabel="cancelar"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'mm aaa',
                            }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Box
                    sx={{
                      width: width < 1100 ? '100%' : '20%',
                      mr: width < 1100 ? 0 : '2%',
                      mb: width < 1100 ? 2 : 0,
                    }}
                  >
                    <b>Obra</b>
                    <Autocomplete
                      id="combo-box-demo"
                      name="obra"
                      size="small"
                      noOptionsText="Sem dados"
                      sx={{ mt: -2 }}
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      options={obras || []}
                      // onInputChange={(event, value, reason) => {
                      //   if (reason == 'clear') {
                      //     setData({
                      //       ...data,
                      //       encarregado: { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 },
                      //     });
                      //   }
                      // }}
                      filterOptions={(options, state) => {
                        return options.filter(
                          (option) =>
                            option.id.length > 0 &&
                            `${option.id} - ${option.descricao}`
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase()),
                        );
                      }}
                      getOptionLabel={
                        (option) => {
                          if (option && option.id) {
                            if (option.descricao && option.descricao) {
                              return `${option.id} - ${option.descricao}  `;
                            }
                          }
                          return '';
                        }
                        // option.id ? `${option.id} - ${option.nome} - ${option.nome2}` : ''
                      }
                      isOptionEqualToValue={(option, value) => {
                        return value && value.id === option.id;
                      }}
                      renderTags={(options) => {
                        return options.map((option) => (
                          <>
                            {option.aberta === 0 ? (
                              <>
                                <FiX color="tomato" size={25} style={{ marginTop: '-15px' }} />
                                <p>
                                  {option.id} - {option.descricao}
                                </p>
                              </>
                            ) : (
                              <>
                                <FiCheck
                                  color="lightgreen"
                                  size={25}
                                  style={{ marginTop: '-15px' }}
                                />
                                <p>
                                  {option.id} - {option.descricao}
                                </p>
                              </>
                            )}
                          </>
                        ));
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props}>
                            {option.aberta === 0 ? (
                              <>
                                <div>
                                  <FiX color="tomato" size={25} />
                                </div>
                                &nbsp;
                                <span>
                                  {option.id} - {option.descricao}
                                </span>
                                {/* </div> */}
                              </>
                            ) : (
                              <>
                                <FiCheck
                                  color="lightgreen"
                                  size={25}
                                  style={{ marginTop: '-15px' }}
                                />
                                &nbsp;
                                <span>
                                  {option.id} - {option.descricao}
                                </span>
                              </>
                            )}
                          </li>
                        );
                      }}
                      style={{ width: '100%' }}
                      onChange={(e, value) => handleChangeObra(e, value)}
                      renderInput={(params) => (
                        <div style={{ position: 'relative' }}>
                          {params.inputProps.value && (
                            <span
                              style={{
                                position: 'absolute',
                                transform: 'translateY(50%)',
                                marginLeft: '5px',
                                marginTop: '25px',
                              }}
                            >
                              {selectedObra && Object.keys(selectedObra).length > 0 ? (
                                <>
                                  {selectedObra.aberta === 0 ? (
                                    <FiX color="tomato" size={25} style={{ marginTop: '-15px' }} />
                                  ) : (
                                    <FiCheck
                                      color="lightgreen"
                                      size={25}
                                      style={{ marginTop: '-15px', marginLeft: '4px' }}
                                    />
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </span>
                          )}
                          <TextField
                            {...params}
                            // variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { paddingLeft: '32px' },
                            }}
                            margin="normal"
                          />
                        </div>
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: width < 1100 ? '100%' : '20%',
                      mr: width < 1100 ? 0 : '2%',
                      mb: width < 1100 ? 2 : 0,
                    }}
                  >
                    <b>Funcionários</b>
                    <Autocomplete
                      id="funcionarios"
                      name="funcionarios"
                      size="small"
                      noOptionsText="Sem dados"
                      clearText="Limpar"
                      sx={{ mt: -2 }}
                      openText="Abrir"
                      closeText="Fechar"
                      autoComplete
                      includeInputInList
                      onChange={(e, value) => handleChangeFuncs(e, value)}
                      options={funcionarios || []}
                      getOptionLabel={(option) => `${option.id} - ${option.nome} - ${option.nome2}`}
                      // filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // variant="outlined"
                          margin="normal"
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: width < 1100 ? '100px' : '7%',
                      mr: width < 1100 ? 0 : '2%',
                      mb: width < 1100 ? 2 : 0,
                      mt: width < 1100 ? 0 : 2.5,
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={(e) => handleSend(e)}
                      className={classes.btnOrange}
                    >
                      Filtrar
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      width: width < 1100 ? '100%' : '30%',
                      mb: width < 1100 ? 2 : 0,
                    }}
                  >
                    <b>Pesquisar</b>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="search"
                      type="text"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <FiSearch />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>

                {/* </Container> */}

                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
                {/* <Button
                    size="small"
                    // color="primary"
                    onClick={(e) => handleSend(e)}
                    style={{ marginTop: '2px' }}
                    className={classes.btnOrange}
                  >
                    Obter Dados
                  </Button> */}
                <Row style={{ marginTop: '10px' }}>
                  <Col sm={3}>
                    {/* <FormGroup> */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ordenacao}
                          onChange={handleChangeOrdenacao}
                          name="ordenacao"
                        />
                      }
                      label="Ordenar por nº mecanográfico"
                    />
                    {/* </FormGroup> */}
                    {valorHora ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={mostrarValor}
                            onChange={handleChangeMostrarValor}
                            name="valor"
                          />
                        }
                        label="Mostrar Valores"
                      />
                    ) : null}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={mostrarVazio}
                          onChange={handleChangeMostrarVazio}
                          name="vazio"
                        />
                      }
                      label="Mostrar todos os colaboradores"
                    />
                  </Col>
                </Row>
              </Col>
              {/* </div> */}
              <TabelaRelatorio
                fdate={selectedMonth}
                btnObterDados={btnObterDados}
                setBtnObterDados={setBtnObterDados}
                classes={classes}
                dataInicio={dataInicio}
                dataFim={dataFim}
                searchText={searchText}
                mostrarValor={mostrarValor}
                mostrarVazio={mostrarVazio}
                valorHora={valorHora}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CardRelatorio;

import React from 'react';
// ACTIONS
// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Modal, Backdrop, Fade, Button, TextField, CircularProgress, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import AdicionarTipoFalta from '../../../../../redux/actions/TiposFaltasAction/adicionarTipoFaltaAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

function ModalAdicionar({
  openAdicionar,
  handleClose,
  tipoFalta,
  setTipoFalta,
  validateOnChange = true,
  validationTipoFalta,
  errorsTipoFalta = null,
  fetchTiposFaltas,
  show,
  // Loading
  loading,
  setLoading,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    setTipoFalta({
      ...tipoFalta,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationTipoFalta({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationTipoFalta()) {
      setLoading(true);
      const ydata = [
        {
          descricao: tipoFalta.descricao,
          abreviatura: tipoFalta.abreviatura,
        },
      ];

      dispatch(AdicionarTipoFalta(ydata, show))
        .then(() => handleClose('adicionar', e))
        .then(() => fetchTiposFaltas());
    }
  };

  const CHARACTER_LIMIT = 3;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openAdicionar}
      onClose={(e) => handleClose('adicionar', e)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openAdicionar}>
        <Box sx={style}>
          <div className="header-modal">
            <h3 id="transition-modal-title" className="text-underline-title-dark">
              Adicionar Tipo de Falta
            </h3>
            <p id="transition-modal-description">Pode adicionar aqui um tipo de falta.</p>
          </div>

          <Box sx={{ mb: 2 }}>
            <b>Descrição</b>
            <TextField
              id="Descricao"
              name="descricao"
              size="small"
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              className="nome"
              {...(errorsTipoFalta.descricao && {
                error: true,
                helperText: errorsTipoFalta.descricao,
              })}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <b>Abreviatura</b>
            <TextField
              id="abreviatura"
              name="abreviatura"
              size="small"
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              className="nome"
              inputProps={{ maxLength: 3 }}
              helperText={
                (tipoFalta &&
                  tipoFalta.abreviatura &&
                  `${Object.keys(tipoFalta.abreviatura).length}/${CHARACTER_LIMIT}`) ||
                ''
              }
              {...(errorsTipoFalta.abreviatura && {
                error: true,
                helperText: errorsTipoFalta.abreviatura,
              })}
            />
          </Box>

          <br />
          <br />
          <div className={classes.modalBtns}>
            <Button
              className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
              disabled={!!loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading && (
                <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
              )}
              Confirmar
            </Button>

            <Button
              className={classes.btnCancelar}
              variant="contained"
              onClick={(e) => handleClose('add', e)}
            >
              Cancelar
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalAdicionar;

import React from 'react';
import PropTypes from 'prop-types';

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

function TopbarSidebarButton({ changeMobileSidebarVisibility, changeSidebarVisibility }) {
  return (
    <div>
      <button
        className="topbar__button topbar__button--desktop"
        type="button"
        onClick={changeSidebarVisibility}
      >
        <img src={icon} alt="topbar1" className="topbar__button-icon" />
      </button>
      <button
        className="topbar__button topbar__button--mobile"
        type="button"
        onClick={changeMobileSidebarVisibility}
      >
        <img src={icon} alt="topbar2" className="topbar__button-icon" />
      </button>
    </div>
  );
}

TopbarSidebarButton.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default TopbarSidebarButton;

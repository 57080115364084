/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumbs, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

// import Link from "@mui/material/Link";

// import Link from "@mui/material/Link";

function BreadListaCriarEquipas() {
  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumb"
      style={{ marginBottom: '10px', marginTop: '12px' }}
    >
      <Link to="/app" color="textPrimary">
        Inicio
      </Link>
      <Typography color="textPrimary">Lista de Obras</Typography>
    </Breadcrumbs>
  );
}
export default BreadListaCriarEquipas;

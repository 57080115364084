/* eslint-disable react/self-closing-comp */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Box } from '@mui/material';

// Redux

// Actions

// Components

function RoleChefeDepartamento({ infoValIndiv, width }) {
  // eslint-disable-next-line no-unused-vars
  const [horas, setHoras] = useState(infoValIndiv.qtd);
  // eslint-disable-next-line no-unused-vars
  const [checkbox, setCheckbox] = useState((infoValIndiv && infoValIndiv.validadoCD) || false);
  const [valueObservacoesTextField, setValueObservacoesTextField] = useState('');

  useEffect(() => {
    setHoras(infoValIndiv.qtd);
    setCheckbox(infoValIndiv.validadoCD);
    setValueObservacoesTextField(infoValIndiv.observacoesCD || null);
  }, [infoValIndiv]);

  const handleChangeObservacoes = (e) => {
    setValueObservacoesTextField(e.target.value);

    infoValIndiv.observacoes = e.target.value;

    // dispatch(GuardarTempos(recursosAssociados));
  };

  const handleFaltas = (falta) => {
    setCheckbox(falta);

    infoValIndiv.faltou = falta;
    // dispatch(GuardarTempos(recursosAssociados));
  };

  return (
    <>
      <Box sx={{ borderBottom: '1px solid #000', my: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Chefe de Departamento
        </h4>
      </Box>
      <Box sx={{ display: 'flex', my: 2 }}>
        <Box sx={{ width: width < 1100 ? '0%' : '20%' }}></Box>
        <Box sx={{ width: width < 1100 ? '28%' : '10%', mr: 2, mt: 2.5, height: '40px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox}
                onChange={() => {
                  // setCheckbox(!infoValIndiv.validadoCD);
                  handleFaltas(!checkbox);
                }}
                name="falta"
              />
            }
            label="Validado"
            disabled
            // style={{ width: '20%' }}
          />
        </Box>
        <Box style={{ width: '70%' }}>
          <b>Obs. Chefe depart.</b>
          <TextField
            name="observacoes"
            variant="outlined"
            style={{ width: '100%' }}
            size="small"
            value={valueObservacoesTextField || ''}
            onChange={handleChangeObservacoes}
            disabled
          />
        </Box>
      </Box>
    </>
  );
}

export default RoleChefeDepartamento;

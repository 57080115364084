/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormHelperText, Autocomplete, Box } from '@mui/material';

function FormEditarUser({
  handleFields,
  user,
  onSubmit,
  setUser,
  validation,
  validateOnChange = true,
  errors,
}) {
  const recursos = useSelector((state) => state.users.funcionarios);
  const perfis = useSelector((state) => state.users.perfis);
  const handleChangeRecursos = (value) => {
    console.log(value);
    if (value) {
      setUser({
        ...user,
        recursosId: value.id,
        recursosIdAutoCompleteChange: value,
      });
      if (validateOnChange)
        validation({ recursosId: value.id, recursosIdAutoCompleteChange: value });
    }
  };

  const handle = (e) => {
    setUser({
      ...user,
      perfisId: parseInt(e.target.value, 10),
    });
  };

  return (
    <Col md={12} lg={12}>
      <form onSubmit={onSubmit}>
        <Box sx={{ mb: 2 }}>
          <b>Nome Completo</b>
          <TextField
            id="NomeCompleto"
            name="nome"
            size="small"
            color="primary"
            value={user.nome}
            onChange={handleFields}
            fullWidth
            autoComplete="off"
            // required
            {...(errors.nome && { error: true, helperText: errors.nome })}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <b>Utilizador</b>
          <TextField
            id="Username"
            name="username"
            size="small"
            color="primary"
            value={user.username || ''}
            onChange={handleFields}
            fullWidth
            autoComplete="off"
            // required
            {...(errors.username && { error: true, helperText: errors.username })}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <b>Funcionário</b>
          <Autocomplete
            name="recursosId"
            noOptionsText="Sem dados"
            clearText="Limpar"
            size="small"
            openText="Abrir"
            closeText="Fechar"
            onChange={(e, value) => handleChangeRecursos(value)}
            value={user.recursosIdAutoCompleteChange}
            options={recursos}
            getOptionLabel={(option) =>
              `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
            }
            isOptionEqualToValue={(option, value) => {
              if (!value.id) return true;

              return value.id === option.id;
            }}
            id="auto-complete"
            autoComplete
            includeInputInList
            renderInput={(params) => (
              <TextField
                {...params}
                // required
                {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
              />
            )}
          />
        </Box>
        {user.perfisId != 2 ? (
          <div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user.perfisId}
                onChange={handle}
              >
                {perfis.map((perfil) => (
                  <MenuItem key={perfil.id} value={perfil.id}>
                    {perfil.descricao}
                  </MenuItem>
                ))}
              </Select>
              {errors.perfisId && (
                <FormHelperText style={{ color: 'red' }}>{errors.perfisId}</FormHelperText>
              )}
            </FormControl>
          </div>
        ) : null}
      </form>
    </Col>
  );
}

FormEditarUser.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(FormEditarUser);

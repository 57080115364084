/* eslint-disable max-len */
import React, { useState } from 'react';
import { Box, Button } from '@mui/material';

import ModalAdicionar from './modalAdicionar';
import ModalDelete from './modalDelete';
import ModalEdit from './modalEdit';
import CardObservacoes from './cardObservacao';

function ObsFuncionarios({
  classes,
  defaultMaterialTheme,
  observacoes,
  funcId,
  fetchFuncionarioObservacoes,
  show,
}) {
  const [observacao, setObservacao] = useState({ obs: '' });
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [obserId, setObserId] = useState(null);
  // const [obserObs, setObserObs] = useState('');

  const [loading, setLoading] = useState(false);

  // const handleOpenMenu = (index, e) => {
  //   // console.log(index);
  //   setAnchorEl({ [index]: e.currentTarget });
  // };

  // const handleCloseMenu = () => {
  //   setAnchorEl(null);
  // };

  // Open Modals (editar e delete)

  // console.log(observacoes)

  const handleOpen = (btn, e, obsId, obsObservacao) => {
    e.preventDefault();
    setLoading(false);
    if (btn == 'edit') {
      //   fetchFuncionarioAusencia(id);
      setOpenEdit(true);
      setObserId(obsId);
      setObservacao({ ...observacao, obs: obsObservacao.rowData[2] });

      console.log(observacao);
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setObserId(obsId);

      //   setAusenciaId(id);
      //   resetAusencia();
    } else {
      setOpenAdicionar(true);
      //   dispatch({ type: 'CLEAR_AUSENCIA', payload: {} });
      //   resetAusencia();
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    // setErrorsTipoFalta({});
    // setObservacao({ ...observacao, obs: '' });
    if (btn == 'edit') {
      setOpenEdit(false);
      setObserId(null);
      // setObservacao(...observacao, { obs: '' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
      setObserId(null);
    } else {
      setOpenAdicionar(false);

      // setErrorsTipoFalta({});
    }
  };

  // -------------------------- Validacao Observacoes --------------------//

  const [errorsObservacao, setErrorsObservacao] = useState({});

  const validationObservacao = (fieldValues = observacao) => {
    const temp = { ...errorsObservacao };

    if ('obs' in fieldValues) temp.obs = fieldValues.obs ? '' : 'Campo Obrigatório';

    setErrorsObservacao({
      ...temp,
    });
    if (fieldValues == observacao) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <>
      <ModalAdicionar
        openAdicionar={openAdicionar}
        handleClose={handleClose}
        defaultMaterialTheme={defaultMaterialTheme}
        observacao={observacao}
        setObservacao={setObservacao}
        funcId={funcId}
        fetchFuncionarioObservacoes={fetchFuncionarioObservacoes}
        show={show}
        validationObservacao={validationObservacao}
        errorsObservacao={errorsObservacao}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        fetchFuncionarioObservacoes={fetchFuncionarioObservacoes}
        funcId={funcId}
        // ausenciaId={ausenciaId}
        show={show}
        obserId={obserId}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <ModalEdit
        openEdit={openEdit}
        handleClose={handleClose}
        defaultMaterialTheme={defaultMaterialTheme}
        observacao={observacao}
        setObservacao={setObservacao}
        obserId={obserId}
        // obserObs={obserObs}
        // setObserObs={setObserObs}
        funcId={funcId}
        fetchFuncionarioObservacoes={fetchFuncionarioObservacoes}
        show={show}
        validationObservacao={validationObservacao}
        errorsObservacao={errorsObservacao}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <Box sx={{ mb: 3 }}>
        <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
          <h4
            style={{
              width: '100%',
            }}
            className="text-underline-title"
          >
            Registos
          </h4>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            size="sm"
            variant="contained"
            className={classes.btnConsultar}
            onClick={(e) => handleOpen('add', e)}
          >
            Adicionar
          </Button>
        </Box>

        <CardObservacoes
          observacoes={observacoes}
          classes={classes}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
}

export default ObsFuncionarios;

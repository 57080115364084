/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';

function SenhaJaAssociada({ fetch }) {
  const navigate = useNavigate();

  useEffect(() => {
    fetch();
  }, []);

  const loginRedirect = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
        <p style={{ color: 'black' }}>A sua senha já foi definida.</p>
      </div>

      <Button className="account__btn btn btn-primary" color="primary" onClick={loginRedirect}>
        Entrar na conta
      </Button>
    </>
  );
}

export default SenhaJaAssociada;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable quotes */
/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';

import { endOfMonth, startOfMonth, format, differenceInSeconds } from 'date-fns';

import { useLocation } from 'react-router';

import Notification from 'rc-notification';

import { makeStyles, withStyles } from '@mui/styles';

import { yellow, red, orange, grey } from '@mui/material/colors';

import { FullWideNotification } from '../../../../../../shared/components/Notification';

// TABELA
import ModalAdicionar from './components/AdicionarHorasEscritorio/modalAdicionar';
import ModalEditar from './components/EditarHorasEscritorio/modalEditar';
import ModalDelete from './components/modalDelete';
import RegistarHorasForm from './RegistarHorasForm';
import TabelaRegistos from './TabelaRegistos';

// ACTIONS
import GetFuncionariosEscritorio from '../../../../../../redux/actions/TemposActions/Escritorio/GetFuncionariosEscritorioAction';
import ConsultarRegistoEscritorio from '../../../../../../redux/actions/TemposActions/Escritorio/ConsultarRegistoEscritorioAction';
import RegistoEscritorio from '../../../../../../redux/actions/TemposActions/Escritorio/Editar/RegistoEscritorioAction';

// import EnviarValidacaoAD from '../../../../redux/actions/ValidacaoActions/validacaoAdmin/enviarValidacoesAD';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   '& > *': {
  //     margin: theme.spacing(1),
  //     width: '25ch',
  //   },
  // },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConsultar: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnValidar: {
    color: '#FFFFFF',
    backgroundColor: '#E21450',
    '&:hover': {
      backgroundColor: '#44c9a3',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#E21450',
      },
    },
  },
  btnObservacoes: {
    color: '#b3f1ff',
    backgroundColor: '#b3f1ff',
    '&:hover': {
      backgroundColor: '#aae4f2',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#b3f1ff',
      },
    },
  },
  btnObservacoesPreenchido: {
    color: '#b3f1ff',
    backgroundColor: '#b3f1ff',
    '&:hover': {
      backgroundColor: '#aae4f2',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#b3f1ff',
      },
    },
  },
  btnVoltar: {
    height: '30px',
    border: '1px solid #e6e6e6',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnDelete: {
    minWidth: '50px',
    height: '40px',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: '#ff4861',
    fontSize: '18px',
    '&:hover': {
      backgroundColor: '#de3e54',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#de3e54',
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnDeleteDisabled: {
    minWidth: '50px',
    height: '40px',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: '#ebebeb',
    fontSize: '18px',
  },
  horasBorder: {
    width: '80px',
    height: '40px',
    textAlign: 'center',
    border: '1px solid #f88e7d',
    borderRadius: '5px',
    padding: '8px',
  },
  buttonsUltimo: {
    // padding: '0px 0px !important',

    color: 'white',
    backgroundColor: orange[600],
    '&:hover': {
      backgroundColor: orange[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: orange[600],
      },
    },
    minWidth: '50px',
    height: '40px',
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },

  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnTipo: {
    color: 'grey',
    fontWeight: 'bold',
    border: '1px solid #707070',
    backgroundColor: '#ebebeb',
    width: '45%',
    height: '40px',
    '&:hover': {
      backgroundColor: '#d4d4d4',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },

  btnTipoSelecionado: {
    color: '#b8b8b8',
    fontWeight: 'bold',
    border: '1px solid #b8b8b8',
    backgroundColor: '#fff',
    width: '45%',
    height: '40px',
    '&:hover': {
      backgroundColor: '#f7f7f7',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  buttons: {
    // padding: '0px 0px !important',
    color: '#E21450',
    minWidth: '45px',
    height: '40px',
    marginRight: '8px !important',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  horasBorderPeriodoTextfield: {
    width: '50px',
    height: '40px',
    textAlign: 'center',
    border: '1px solid #000',
    backgroundColor: '#ebebeb',
    borderRadius: '5px',
    padding: '8px',
  },
  horasBorderPeriodo: {
    width: '50px',
    height: '40px',
  },
}));

const customStyles = (theme) => ({
  BusinessAnalystRow: {
    // '& td': { backgroundColor: '#FFF' },
    '& tr.Mui-selected:hover': { backgroundColor: '#CCC' },
  },
  LinhaValidada: {
    '& td': { backgroundColor: 'rgb(255, 153, 145, 0.1)' },
    // '& tr.Mui-selected:hover': { backgroundColor: '#CCC' },
  },
  GreyLine: {
    '& td': { backgroundColor: theme.palette.grey[200] },
  },
  NameCell: {
    fontWeight: 900,
  },
});

let notificationTC = null;

function RegistarHorasCard({ theme, useWindowDimensions }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const obraId = location.pathname.split('/')[4];

  // ====================== Search Text ====================== //

  const [searchText, setSearchText] = useState();

  // ========================================================= //

  // eslint-disable-next-line no-unused-vars
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [funcionario, setFuncionario] = useState();

  // Novo Registo

  const [novoRegisto, setNovoRegisto] = useState({
    funcionarioId: '',
    tipo: 1,
    obraId: '',
    dataInicio: '', // Utilizado no editar
    dataInicioDiario: '',
    dataInicioPeriodo: '',
    dataFim: '',
    horas: '',
    tipoAtrasoId: '',
    tipoPeriodoId: '',
    faltou: '',
    observacoes: '',
    qtd: '', // Utilizado no editar
    qtdDiario: 0,
    qtdPeriodo: 0,
  });

  const [horas, setHoras] = useState(novoRegisto.qtd);
  const [valueTipoAtrasoAutoComplete, setValueTipoAtrasoAutoComplete] = useState({
    id: 0,
    descricao: '',
  });
  const [valueTipoPeriodoAutoComplete, setValueTipoPeriodoAutoComplete] = useState({
    id: 0,
    descricao: '',
  });

  const [registoId, setRegistoId] = useState();

  const [loading, setLoading] = useState(false);

  const tiposperiodos = useSelector((state) => state.tiposPeriodos.dropdownTiposPeriodos);
  const tiposatrasos = useSelector((state) => state.tiposAtrasos.dropdownTiposAtrasos);

  const resetRegisto = () => {
    setNovoRegisto({
      funcionarioId: '',
      tipo: 1,
      obraId,
      dataInicio: new Date(),
      dataInicioDiario: new Date(),
      dataInicioPeriodo: startOfMonth(new Date()),
      dataFim: endOfMonth(new Date()),
      horas: '',
      tipoAtrasoId: '',
      tipoPeriodoId: '',
      faltou: false,
      observacoes: '',
      qtd: 0,
      qtdDiario: 0,
      qtdPeriodo: 0,
    });
  };

  const registoEscritorio = useSelector((state) => state.tempos.registoEscritorio);

  const funcionariosEscritorio = useSelector((state) => state.tempos.funcionariosEscritorio);

  const fetchFuncionarios = () => {
    dispatch(GetFuncionariosEscritorio());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchFuncionarios();
  }, []);

  const handleDataInicioChange = (date) => {
    setDataInicio(date);
  };

  const handleDataFimChange = (date) => {
    setDataFim(date);
  };

  const handleChangeFuncionario = (e, value) => {
    e.preventDefault();

    setFuncionario(value);
  };

  // -------------------------- Validacao novo registo --------------------//
  // Diário

  const [errorsNovoRegistoDiario, setErrorsNovoRegistoDiario] = useState({});

  const validationNovoRegistoDiario = (fieldValues = novoRegisto) => {
    const temp = { ...errorsNovoRegistoDiario };
    if ('funcionarioId' in fieldValues)
      temp.funcionarioId = fieldValues.funcionarioId ? '' : 'Campo Obrigatório';

    if ('dataInicioDiario' in fieldValues)
      temp.dataInicioDiario = fieldValues.dataInicioDiario != null ? '' : 'Campo Obrigatório';

    setErrorsNovoRegistoDiario({
      ...temp,
    });
    if (fieldValues == novoRegisto) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // Periodo

  const [errorsNovoRegistoPeriodo, setErrorsNovoRegistoPeriodo] = useState({});

  const validationNovoRegistoPeriodo = (fieldValues = novoRegisto) => {
    const temp = { ...errorsNovoRegistoPeriodo };
    const intervalo = differenceInSeconds(
      fieldValues.dataFim ? new Date(fieldValues.dataFim) : new Date(novoRegisto.dataFim),
      fieldValues.dataInicioPeriodo
        ? new Date(fieldValues.dataInicioPeriodo)
        : new Date(novoRegisto.dataInicioPeriodo),
    );

    if ('funcionarioId' in fieldValues)
      temp.funcionarioId = fieldValues.funcionarioId ? '' : 'Campo Obrigatório';

    if ('dataInicioPeriodo' in fieldValues)
      temp.dataInicioPeriodo = fieldValues.dataInicioPeriodo != null ? '' : 'Campo Obrigatório';

    if ('dataFim' in fieldValues)
      temp.dataFim = fieldValues.dataFim != null ? '' : 'Campo Obrigatório';

    if (fieldValues.dataInicioPeriodo != null && fieldValues.dataFim != null) {
      if (intervalo < 0) {
        temp.dataInicioPeriodo = 'Data de inicio tem de ser menor que Data de fim';
      } else {
        temp.dataInicioPeriodo = '';
      }
    }

    setErrorsNovoRegistoPeriodo({
      ...temp,
    });
    if (fieldValues == novoRegisto) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -------------------------- Modals -----------------------------//

  const [openEditar, setOpenEditar] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);

  const toogle = () => {
    const atrasos =
      tiposatrasos && tiposatrasos.find((atraso) => atraso.id === registoEscritorio.tipoatrasos_id);
    const periodos =
      tiposperiodos &&
      tiposperiodos.find((periodo) => periodo.id === registoEscritorio.tipoperiodos_id);
    const funcEscritorio =
      funcionariosEscritorio &&
      funcionariosEscritorio.find((func) => func.id === registoEscritorio.recursos_id);

    setNovoRegisto({
      funcionarioId: funcEscritorio,
      obraId: registoEscritorio.obras_id,
      dataInicio: registoEscritorio && new Date(registoEscritorio.data),
      tipoAtrasoId: atrasos,
      tipoPeriodoId: periodos,
      faltou: registoEscritorio.faltou,
      observacoes: registoEscritorio.observacoes,
      qtd: registoEscritorio.qtd,
    });

    setValueTipoAtrasoAutoComplete(atrasos);

    setValueTipoPeriodoAutoComplete(periodos);
  };

  useEffect(() => {
    toogle();
  }, [registoEscritorio]);

  const fetchRegistoEscritorio = (registo) => {
    return dispatch(RegistoEscritorio(registo));
  };

  const handleOpen = (btn, e, value, tableMeta) => {
    e.preventDefault();
    setLoading(false);
    if (btn == 'edit') {
      const registo = tableMeta.rowData[0];
      setRegistoId(registo);
      setErrorsNovoRegistoDiario({});
      fetchRegistoEscritorio(value, e).then(() => setOpenEditar(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      const registo = tableMeta.rowData[0];
      setRegistoId(registo);
    } else {
      setOpenAdicionar(true);
      resetRegisto();

      // resetTipoAtraso();
    }
    setValueTipoAtrasoAutoComplete({
      id: 0,
      descricao: '',
    });

    setValueTipoPeriodoAutoComplete({
      id: 0,
      descricao: '',
    });
    setErrorsNovoRegistoDiario({});
    setErrorsNovoRegistoPeriodo({});
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    // setErrorsTipoAtraso({});
    if (btn == 'edit') {
      setOpenEditar(false);

      // dispatch({ type: 'CLEAR_DATA_TIPOAtraso' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
      // setErrorsTipoAtraso({});
    }
  };

  // -----------------------------------------------------------------------//

  // ------------------------- Notification Sytem ------------------------ //

  useEffect(() => {
    Notification.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ------------------------------------------------------------- //

  // ------------------------- CONSULTAR --------------------------//

  const funcionarioId = funcionario && funcionario.id;

  const handleConsultar = () => {
    const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
    const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
    dispatch(
      ConsultarRegistoEscritorio(selectedDataInicio, selectedDataFim, obraId, funcionarioId),
    );
  };

  useEffect((e) => {
    resetRegisto();
    handleConsultar(e);
  }, []);

  // --------------------------------------------------------- //

  return (
    <>
      <ModalAdicionar
        openAdicionar={openAdicionar}
        handleClose={handleClose}
        novoRegisto={novoRegisto}
        setNovoRegisto={setNovoRegisto}
        funcionariosEscritorio={funcionariosEscritorio}
        // handleOpenAlert={handleOpenAlert}
        useWindowDimensions={useWindowDimensions}
        validationNovoRegistoDiario={validationNovoRegistoDiario}
        validationNovoRegistoPeriodo={validationNovoRegistoPeriodo}
        errorsNovoRegistoDiario={errorsNovoRegistoDiario}
        errorsNovoRegistoPeriodo={errorsNovoRegistoPeriodo}
        show={show}
        classes={classes}
        horas={horas}
        setHoras={setHoras}
        handleConsultar={handleConsultar}
        valueTipoAtrasoAutoComplete={valueTipoAtrasoAutoComplete}
        setValueTipoAtrasoAutoComplete={setValueTipoAtrasoAutoComplete}
        valueTipoPeriodoAutoComplete={valueTipoPeriodoAutoComplete}
        setValueTipoPeriodoAutoComplete={setValueTipoPeriodoAutoComplete}
        // loading button
        loading={loading}
        setLoading={setLoading}
      />
      <ModalEditar
        openEditar={openEditar}
        handleClose={handleClose}
        novoRegisto={novoRegisto}
        setNovoRegisto={setNovoRegisto}
        funcionariosEscritorio={funcionariosEscritorio}
        useWindowDimensions={useWindowDimensions}
        validationNovoRegistoDiario={validationNovoRegistoDiario}
        errorsNovoRegistoDiario={errorsNovoRegistoDiario}
        show={show}
        classes={classes}
        horas={horas}
        setHoras={setHoras}
        valueTipoAtrasoAutoComplete={valueTipoAtrasoAutoComplete}
        setValueTipoAtrasoAutoComplete={setValueTipoAtrasoAutoComplete}
        valueTipoPeriodoAutoComplete={valueTipoPeriodoAutoComplete}
        setValueTipoPeriodoAutoComplete={setValueTipoPeriodoAutoComplete}
        registoId={registoId}
        obraId={obraId}
        handleConsultar={handleConsultar}
        // loading button
        loading={loading}
        setLoading={setLoading}
      />
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        handleConsultar={handleConsultar}
        registoId={registoId}
        obraId={obraId}
        show={show}
      />

      <Container>
        <Row>
          <RegistarHorasForm
            dataInicio={dataInicio}
            dataFim={dataFim}
            funcionario={funcionario}
            obraId={obraId}
            linhasSelecionadas={linhasSelecionadas}
            funcionariosEscritorio={funcionariosEscritorio}
            classes={classes}
            setDataInicio={setDataInicio}
            setDataFim={setDataFim}
            setFuncionario={setFuncionario}
            handleDataInicioChange={handleDataInicioChange}
            handleDataFimChange={handleDataFimChange}
            handleChangeFuncionario={handleChangeFuncionario}
            handleConsultar={handleConsultar}
            handleOpen={handleOpen}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Row>
        <Row>
          <Col>
            <TabelaRegistos classes={classes} handleOpen={handleOpen} searchText={searchText} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withStyles(customStyles)(RegistarHorasCard);

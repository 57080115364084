/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import './permissoes.css';
import { Button, Checkbox, FormControlLabel, CircularProgress, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EditarUserPermissoes from '../../../../../../../redux/actions/UsersActions/EditarUserPermissoesAction';

function CardPermissoes({ show, classes }) {
  const dispatch = useDispatch();

  const utilizador = useSelector((state) => state.users.user);

  const [loading, setLoading] = useState(false);

  const handleChange = (permissao, grupo) => {
    const grupoId = utilizador.permissoes.findIndex((p) => p.grupo === grupo);

    const permissaoSelecionada = utilizador.permissoes
      .find((p) => p.grupo === grupo)
      .lista.findIndex((p) => p.id == permissao.id);

    utilizador.permissoes[grupoId].lista[permissaoSelecionada].permissao =
      !utilizador.permissoes[grupoId].lista[permissaoSelecionada].permissao;
    dispatch({
      type: 'GET_USER',
      payload: { ...utilizador },
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const ydata = [
      {
        nome: utilizador.nome,
        perfisId: utilizador.perfis_id,
        recursosId: utilizador.recursos_id,
        username: utilizador.username,
        permissoes: utilizador.permissoes
          .map((p) => p.lista)
          .flat()
          .map((permissao) => {
            if (permissao.permissao) {
              return permissao.id;
            }
            return null;
          })
          .filter((permissao) => {
            return permissao != null;
          }),
      },
    ];

    dispatch(
      EditarUserPermissoes(
        utilizador.id,
        ydata,
        show,
        //  toggle
      ),
    ).then(() => {
      setLoading(false);
    });
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Box>
          <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
            <h4
              style={{
                width: '100%',
              }}
              className="text-underline-title"
            >
              Permissões do utilizador
            </h4>
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {utilizador &&
          utilizador.permissoes &&
          utilizador.permissoes.map((permissao) => (
            <Box sx={{ width: permissao.grupo == 'Menu Lateral' ? '50%' : '25%' }}>
              <Box>
                <p>
                  <b>{permissao.grupo}</b>
                </p>
              </Box>
              <Box>
                {permissao &&
                  permissao.lista &&
                  permissao.lista.map((item) => (
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.permissao == 1}
                            onChange={() => handleChange(item, permissao.grupo)}
                            name={item.descricao}
                          />
                        }
                        label={item.descricao}
                      />
                    </Box>
                  ))}
              </Box>

              {/* {console.log(permissao.grupo)} */}
            </Box>
          ))}
      </Box>
      <Box style={{ textAlign: 'right', marginTop: '10px' }}>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          className={loading ? classes.btnGuardarLoading : classes.btnGuardar}
          disabled={!!loading}
          size="small"
        >
          {/* {!loading && <IoMdSave style={{ color: 'white', marginRight: '10px' }} />} */}
          {loading && (
            <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
          )}
          Guardar
        </Button>
      </Box>
    </Box>
  );
}

export default CardPermissoes;

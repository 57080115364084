/* eslint-disable default-param-last */
import { logsHorasInitialState } from '../InitialState/logshoras';

const logshorasReducer = (state = logsHorasInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_LOGS_HORAS':
      return {
        ...state,
        logs: payload,
      };
    default:
      return state;
  }
};

export default logshorasReducer;

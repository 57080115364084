/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
// COMPONENTS
import ValidacaoIndividualForm from './components/validacaoIndividualForm';
import FooterLinks from '../../../../Account/Footer';
// ACTIONS
import GetValidacaoIndividualAD from '../../../../../redux/actions/ValidacaoActions/validacaoAdmin/getValidacaoIndividualAD';

// BREADCRUMS
import BreadValidacaoIndividualAD from '../../../../App/Breadcrumbs/ValidacaoAD/BreadValidacaoIndividualRH';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  btnGuardar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },

  btnVoltar: {
    height: '30px',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
}));

function ValidacaoIndividualAD() {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const id = location.pathname.split('/')[4];

  const fetchInfoValidacaoIndivudal = () => {
    dispatch(GetValidacaoIndividualAD(id));
  };

  const infoValIndiv = useSelector((state) => state.validacao.validacaoIndividualAD);
  const [disabledFaltou, setDisabledFaltou] = useState(infoValIndiv && !infoValIndiv.faltou);
  const [valueTipoFaltaAutoComplete, setValueTipoFaltaAutoComplete] = useState({
    id: 0,
    descricao: '',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInfoValidacaoIndivudal();
  }, []);
  useEffect(() => {
    setDisabledFaltou(infoValIndiv && !infoValIndiv.faltou);
  }, [infoValIndiv]);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadValidacaoIndividualAD />
            {/* <h3 className="page-title">{t("tables.resizable_table.title")}</h3> */}
            <h3 className="page-title" style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to="/app/validacao/administracao" style={{ textDecoration: 'none' }}>
                <ButtonToolbar
                  className="btn-toolbar--center"
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <Button
                    id="TooltipBottom"
                    outline
                    className={classes.btnVoltar}
                    color="secondary"
                    size="sm"
                  >
                    <span
                      className="lnr lnr-chevron-left"
                      style={{ fontSize: 10, fontWeight: 900 }}
                    />
                  </Button>

                  <UncontrolledTooltip
                    // dir={dir}
                    placement="bottom"
                    target="TooltipBottom"
                  >
                    Voltar
                  </UncontrolledTooltip>
                </ButtonToolbar>
              </Link>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                }}
                className="text-underline"
              >
                Validação Individual
              </h4>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ValidacaoIndividualForm
              id={id}
              infoValIndiv={infoValIndiv}
              disabledFaltou={disabledFaltou}
              setDisabledFaltou={setDisabledFaltou}
              valueTipoFaltaAutoComplete={valueTipoFaltaAutoComplete}
              setValueTipoFaltaAutoComplete={setValueTipoFaltaAutoComplete}
              // Loading
              loading={loading}
              setLoading={setLoading}
            />
          </Col>
        </Row>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
}

export default ValidacaoIndividualAD;

/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
import LogInPhoto from '../../Account/LogInPhoto/index';
import Register from '../../Account/Register/index';
import RegisterPhoto from '../../Account/RegisterPhoto/index';
import ResetPassword from '../../Account/ResetPassword/index';
import ResetPasswordPhoto from '../../Account/ResetPasswordPhoto';
import DefSenha from '../../Account/DefinirSenha';
import AppDashboard from '../../Dashboards/App/index';

// Components

import RegistarHoras from '../../Dashboards/App/RegistarHoras';
import RegistarHorasEscritorio from '../../Dashboards/App/RegistarHoras/indexEscritorio';
import Horas from '../../Dashboards/App/RegistarHoras/Horas';
import HorasEscritorio from '../../Dashboards/App/RegistarHoras/Escritorio';
import Obras from '../../Dashboards/App/Obras';
import ObrasDetalhes from '../../Dashboards/App/Obras/Detalhes';
import ListaUtilizadores from '../../Dashboards/App/Utilizadores';
import Utilizador from '../../Dashboards/App/Utilizadores/Utilizador';

// import Relatorios from '../../../Dashboards/App/Relatorios/Relatorios';
import RelatorioMensalDeHoras from '../../Dashboards/App/Relatorios/MensalDeHoras/RelatorioMensalDeHoras';
import RelatorioColaboradoresAtivos from '../../Dashboards/App/Relatorios/ColaboradoresAtivos/RelatorioColaboradoresAtivos';
import RelatorioVistaGeralDeProjeto from '../../Dashboards/App/Relatorios/VistaGeralDeProjeto/RelatorioVistaGeralDeProjeto';

import ListaFuncionarios from '../../Dashboards/App/Funcionarios';
import Funcionario from '../../Dashboards/App/Funcionarios/Funcionario/index';
import TiposPeriodos from '../../Dashboards/App/TiposPeriodos/tiposperiodos.jsx';
import TiposFaltas from '../../Dashboards/App/TiposFaltas/tiposfaltas.jsx';
import TiposAtrasos from '../../Dashboards/App/TiposAtrasos/tiposatrasos.jsx';
import ValidacaoCD from '../../Dashboards/App/ValidacaoCD';
import ValidacaoIndividualCD from '../../Dashboards/App/ValidacaoCD/ValidacaoIndividual/index';
import ValidacaoRH from '../../Dashboards/App/ValidacaoRH';
import ValidacaoIndividualRH from '../../Dashboards/App/ValidacaoRH/ValidacaoIndividual/index';
import ValidacaoAD from '../../Dashboards/App/ValidacaoAD';
import ValidacaoIndividualAD from '../../Dashboards/App/ValidacaoAD/ValidacaoIndividual/index';
import CriarEquipas from '../../Dashboards/App/CriarEquipas';
import Equipas from '../../Dashboards/App/CriarEquipas/Equipas';

// Private Route
import ProtectedRoute from './ProtectedRoute';
import LogsHoras from '../../Dashboards/App/LogsHoras';

function Router() {
  // <MainWrapper>
  //   <main>
  const permissoes = useSelector((state) => state.user.permissoes);
  const location = useLocation();
  const isFetching = useSelector((state) => state.appConfig.isFetching);
  const customizer = useSelector((state) => state.customizer);
  const rtl = useSelector((state) => state.rtl);
  const theme = useSelector((state) => state.theme);

  const storage = localStorage;

  return (
    <MainWrapper
      location={location}
      isFetching={isFetching}
      customizer={customizer}
      rtl={rtl}
      theme={theme}
    >
      <main>
        <Routes>
          <Route exact path="/" element={<LogIn />} />
          <Route exact path="/login" element={<LogIn />} />
          <Route exact path="/definirsenha/:hash" element={<DefSenha />} />
          <Route exact path="/log_in_photo" element={<LogInPhoto />} />
          {/* <Route exact path="/404" element={<NotFound404 />} /> */}
          <Route exact path="/lock_screen" element={<LockScreen />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/register_photo" element={<RegisterPhoto />} />
          <Route exact path="/reset_password" element={<ResetPassword />} />
          <Route exact path="/reset_password_photo" element={<ResetPasswordPhoto />} />
          <Route
            exact
            path="/app"
            element={
              <ProtectedRoute redirectPath="/" isAllowed={!!storage.token}>
                <AppDashboard />
              </ProtectedRoute>
            }
          />
          {/* <Route exact path="/app" element={<AppDashboard />} /> */}

          {/* =============================================================== */}

          <Route
            exact
            path="/app/criarEquipas"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('CRIAR_EQUIPAS')}
              >
                <CriarEquipas />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/criarEquipas/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('CRIAR_EQUIPAS')}
              >
                <Equipas />
              </ProtectedRoute>
            }
          />

          {/* Registar Horas */}
          <Route
            exact
            path="/app/registarHoras/operacionais"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('REGISTAR_HORAS_OPERACIONAIS')}
              >
                <RegistarHoras />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/registarHoras/operacionais/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('REGISTAR_HORAS_OPERACIONAIS')}
              >
                <Horas />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/app/registarHoras/escritorio"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('REGISTAR_HORAS_ESCRITORIO')}
              >
                <RegistarHorasEscritorio />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/app/registarHoras/escritorio/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('REGISTAR_HORAS_ESCRITORIO')}
              >
                <HorasEscritorio />
              </ProtectedRoute>
            }
          />

          {/* Obras */}
          <Route
            exact
            path="/app/obras"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('OBRAS')}
              >
                <Obras />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/obras/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('OBRAS')}
              >
                <ObrasDetalhes />
              </ProtectedRoute>
            }
          />
          {/* Validacao CD */}
          <Route
            exact
            path="/app/validacao/chefedepartamento"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('VALIDACAO_CHEFE_DEPART')}
              >
                <ValidacaoCD />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/validacao/chefedepartamento/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('VALIDACAO_CHEFE_DEPART')}
              >
                <ValidacaoIndividualCD />
              </ProtectedRoute>
            }
          />
          {/* Validacao RH */}
          <Route
            exact
            path="/app/validacao/recursoshumanos"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('VALIDACAO_REC_HUMANOS')}
              >
                <ValidacaoRH />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/validacao/recursoshumanos/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('VALIDACAO_REC_HUMANOS')}
              >
                <ValidacaoIndividualRH />
              </ProtectedRoute>
            }
          />
          {/* Validacao Admin */}
          <Route
            exact
            path="/app/validacao/administracao"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('VALIDACAO_ADMINISTRACAO')}
              >
                <ValidacaoAD />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/validacao/administracao/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('VALIDACAO_ADMINISTRACAO')}
              >
                <ValidacaoIndividualAD />
              </ProtectedRoute>
            }
          />
          {/* Relatorios  */}
          {/* <PrivateRoute
            exact
            path="/app/relatorios"
            element={Relatorios}
            permissao="RELATORIOS"
          /> */}
          <Route
            exact
            path="/app/relatorios/mensal-de-horas"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('RELATORIOS_MENSAL_HORAS')}
              >
                <RelatorioMensalDeHoras />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/relatorios/colaboradores-ativos"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('RELATORIOS_COLABORADORES_ATIVOS')}
              >
                <RelatorioColaboradoresAtivos />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/relatorios/vista-geral-de-projeto"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('RELATORIOS_VISTA_GERAL_PROJETO')}
              >
                <RelatorioVistaGeralDeProjeto />
              </ProtectedRoute>
            }
          />
          {/* ADMINISTRACAO */}
          {/* ADMINISTRACAO - Utilizadores */}
          <Route
            exact
            path="/app/administracao/utilizadores"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('UTILIZADORES')}
              >
                <ListaUtilizadores />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/administracao/utilizadores/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('UTILIZADORES')}
              >
                <Utilizador />
              </ProtectedRoute>
            }
          />
          {/* ADMINISTRACAO - Funcionarios */}
          <Route
            exact
            path="/app/administracao/funcionarios"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('FUNCIONARIOS')}
              >
                <ListaFuncionarios />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/administracao/funcionarios/:id"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('FUNCIONARIOS')}
              >
                <Funcionario />
              </ProtectedRoute>
            }
          />
          {/* ADMINISTRACAO - Tipos */}
          <Route
            exact
            path="/app/administracao/tiposperiodos"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('TIPOS_PERIODOS')}
              >
                <TiposPeriodos />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/administracao/tiposfaltas"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('TIPOS_FALTAS')}
              >
                <TiposFaltas />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/app/administracao/tiposatrasos"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('TIPOS_ATRASOS')}
              >
                <TiposAtrasos />
              </ProtectedRoute>
            }
          />
          {/* ADMINISTRACAO - Logs Horas */}
          <Route
            exact
            path="/app/administracao/logshoras"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={permissoes && permissoes.includes('LOGS_HORAS')}
              >
                <LogsHoras />
              </ProtectedRoute>
            }
          />

          {/* =============================================================== */}
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </main>
    </MainWrapper>
  );
}

export default Router;

/* eslint-disable default-param-last */
import { temposInitialState } from '../InitialState/tempos';

const temposReducer = (state = temposInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_OBRAS_COM_RECURSOS':
      return {
        ...state,
        obrasComRecursos: payload,
      };
    case 'GET_RECURSOS_ASSOCIADOS':
      return {
        ...state,
        recursosAssociados: payload,
      };

    case 'POST_TEMPOS':
      return {
        ...state,
        registarTempos: payload,
      };
    case 'GET_INFO_OBRA':
      return {
        ...state,
        infoObra: payload,
      };
    case 'GET_HISTORICO_OBRAS':
      return {
        ...state,
        historicoObras: payload,
      };
    case 'POST_UTILIZADOR':
      return {
        ...state,
        adicionarUtilizador: payload,
      };
    case 'EXCLUIR_FUNCIONARIO':
      return {
        ...state,
        utilizadorExcluido: payload,
      };
    case 'GET_FUNCIONARIOS_NAO_ASSOCIADOS':
      return {
        ...state,
        funcionariosNaoAssociados: payload,
      };
    case 'STORE_OLD_STATE':
      return {
        ...state,
        oldRecursosAssociados: payload,
      };

    // OBRAS REGISTO ESCRITIORIO
    case 'GET_OBRAS_REGISTO_ESCRITORIO':
      return {
        ...state,
        obrasRegistoEscritorio: payload,
      };
    case 'GET_FUNCIONARIOS_ESCRITORIO':
      return {
        ...state,
        funcionariosEscritorio: payload,
      };
    case 'GET_CONSULTAR_REGISTO_ESCRITORIO':
      return {
        ...state,
        consultarRegistoEscritorio: payload,
      };
    case 'POST_REGISTO_ESCRITORIO':
      return {
        ...state,
        adicionarRegistoEscritorio: payload,
      };
    case 'GET_REGISTO_ESCRITORIO':
      return {
        ...state,
        registoEscritorio: payload,
      };
    case 'REMOVER_RELATORIO_ESCRITORIO':
      return {
        ...state,
        removerRelatorioEscritorio: payload,
      };
    // // Periodo
    case 'GET_REGISTO_ESCRITORIO_HORAS_PERIODO':
      return {
        ...state,
        totalHoras: payload,
      };
    default:
      return state;
  }
};

export default temposReducer;

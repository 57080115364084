/* eslint-disable dot-notation */
import React from 'react';

// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';

// Components
import CondicoesUso from './pages/condicoesUso';
import PoliticasPrivacidade from './pages/politicasPrivacidade';
import PoliticasCookies from './pages/politicasCookies';

const useStyles = makeStyles({
  list: {
    // width: '45vw',
    borderRadius: '10px 0px 0px 10px !important',
    // background: 'red !important',
    width: '80vw',
  },
  fullList: {
    // width: 'auto',
    width: '80vw',
  },
  paper: {
    background: '#FAFAFA',
  },
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#E21450',
    border: `1px solid #E21450`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#bc1041',
      border: '1px solid #bc1041',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCloseDrawer: {
    color: '#464255',
    width: '30px',
    height: '40px',
    borderRadius: '15px',
    backgroundColor: '#f2f2f2',
    border: `1px solid #f2f2f2`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      border: '1px solid #e0e0e0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  btnUpload: {
    color: '#E21450',
    // width: '125px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function FooterDrawer({ state, toggleDrawer, drawerState }) {
  const classes = useStyles();

  return (
    <div>
      <Drawer
        // eslint-disable-next-line react/jsx-curly-brace-presence
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.list} style={{ width: '80vw' }}>
          {drawerState === 'condicoesUso' && (
            <CondicoesUso toggleDrawer={toggleDrawer} classes={classes} />
          )}

          {drawerState === 'politicasPrivacidade' && (
            <PoliticasPrivacidade toggleDrawer={toggleDrawer} classes={classes} />
          )}
          {drawerState === 'politicasCookies' && (
            <PoliticasCookies toggleDrawer={toggleDrawer} classes={classes} />
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default FooterDrawer;

/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { Button } from '@mui/material';

import { FiEdit, FiTrash2 } from 'react-icons/fi';
import MUIDataTable from 'mui-datatables';

function CardObservacoes({ observacoes, handleOpen }) {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  const columns = [
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'data',
      label: 'Data',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'observacoes',
      label: 'Observação',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          console.log(value);
          return (
            <div className="table-btns-tiposfaltas">
              <Button
                onClick={(e) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value, tableMeta)
                }
              >
                <FiEdit size={30} strokeWidth={1} color="#000" />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
              >
                <FiTrash2 size={30} strokeWidth={1} color="#000" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    print: false,
    search: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    // customToolbar: () => {
    //   return <CustomToolbar handleOpen={handleOpen} />;
    // },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'Limpar',
      },
    },
  };
  return (
    <>
      <MUIDataTable
        title=""
        description="Nada para mostrar"
        data={observacoes}
        columns={columns}
        options={options}
      />
      {/* {observacoes && observacoes.length > 0 ? (
        <>
          {observacoes.map((obser, index) => (
            <div className={classes.miniCard} key={obser.id}>
              <div className={classes.miniCard_container}>
                <div>
                  <p className={classes.miniCard_date}>
                    <strong>
                      {format(obser.data, 'yyyy-MM-dd')}
                      {obser.data}
                    </strong>
                    <span style={{ margin: '0px 10px' }}>-</span>
                  </p>
                </div>
                <div className={classes.miniCard_obs}>
                  <p>{obser.observacoes}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleOpenMenu(index, e)}
                  >
                    <MoreHorizIcon />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl && anchorEl[index]}
                    keepMounted
                    open={Boolean(anchorEl && anchorEl[index])}
                    onClose={handleCloseMenu}
                  >
                    {console.log(obser.observacoes)}
                    <MenuItem onClick={(e) => handleOpen('edit', e, obser.id, obser)}>
                      Editar
                    </MenuItem>
                    <MenuItem onClick={(e) => handleOpen('delete', e, obser.id, obser)}>
                      Apagar
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          ))}{' '}
        </>
      ) : (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <p>Não existem Observações.</p>
        </div>
      )} */}
    </>
  );
}

export default CardObservacoes;

/* eslint-disable default-param-last */
import { syncFeriasInitialState } from '../InitialState/syncFerias';

const syncFeriasReducer = (state = syncFeriasInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_DIAS_MARCADOS_EMPRESA':
      return {
        ...state,
        diasMarcadosEmpresa: payload,
      };
    case 'GET_DIAS_MARCADOS':
      return {
        ...state,
        diasMarcados: payload,
      };
    case 'GET_DIAS_DISPONIVEIS':
      return {
        ...state,
        diasDisponiveis: payload,
      };
    case 'GET_ALL_DIAS_FERIAS_RECURSO':
      return {
        ...state,
        diasTotaisFerias: payload,
      };
    case 'PUT_ALL_DIAS_FERIAS_RECURSO':
      return {
        ...state,
        editarDiasTotaisFerias: payload,
      };
    case 'GET_FERIADOS':
      return {
        ...state,
        feriados: payload,
      };

    default:
      return state;
  }
};

export default syncFeriasReducer;

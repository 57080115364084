import React, { useState, useEffect } from 'react';
// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Modal, Backdrop, Fade, Button, CircularProgress, Box } from '@mui/material';
import { grey } from '@mui/material/colors';

// ACTIONS
import RecuperarSenha from '../../../../../../../redux/actions/UsersActions/RecuperarSenhaAction';
import FormSenha from '../FormSenha';

// import RemoverMenu from '../../../../utils/redux/actions/menus/removerMenuAction';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

function ModalRedifinirSenha({
  openSenha,
  handleClose,
  info,
  fetchUtilizador,
  show,
  // Loading,
  loading,
  setLoading,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  //* ********************************************************** */
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({
    password: '',
    confirmarPassword: '',
  });

  const resetForm = () => {
    setUser({
      password: '',
      confirmarPassword: '',
    });
    setErrors({});
  };

  const validationPassword = (fieldValues = user) => {
    const temp = { ...errors };
    if ('password' in fieldValues) temp.password = fieldValues.password ? '' : 'Campo Obrigatório';
    if ('confirmarPassword' in fieldValues) {
      if (!user.confirmarPassword) {
        temp.confirmarPassword = 'Campo Obrigatório';
      } else if (user.confirmarPassword === user.password) {
        temp.confirmarPassword = '';
      } else {
        temp.confirmarPassword = 'Verique se a palavra passe é igual';
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (user.password || user.confirmarPassword) validationPassword();
    }, 50);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.confirmarPassword, user.password]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        userId: info.id,
        password: user.password,
        // confirmarPassword: user.confirmarPassword,
      },
    ];

    console.log(ydata);

    if (validationPassword() == true) {
      setLoading(true);
      dispatch(RecuperarSenha(ydata, show))
        .then(() => handleClose('senha', e))
        .then(() => setErrors({}))
        .then(() => fetchUtilizador());
    }
  };
  //* ********************************************************** */
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openSenha}
      onClose={(e) => handleClose('senha', e)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openSenha}>
        <Box sx={style}>
          <div className="header-modal">
            <h3 id="transition-modal-title" className="text-underline-title-dark">
              Redefinir senha
            </h3>
            <p id="transition-modal-description">
              Preencher o formulário para redefinir a senha de <b>{info.nome}</b>
            </p>
          </div>
          <FormSenha
            user={user}
            setUser={setUser}
            // onSubmit={handleSubmit}
            toggle={resetForm}
            errors={errors}
            validationPassword={validationPassword}
          />
          <br />
          <br />
          <div className={classes.modalBtns}>
            <Button
              className={classes.btnCancelar}
              variant="contained"
              onClick={(e) => handleClose('senha', e)}
            >
              Cancelar
            </Button>
            <Button
              className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
              disabled={!!loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading && (
                <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
              )}
              Confirmar
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalRedifinirSenha;

/* eslint-disable default-param-last */
import { tipoPeriodoInitialState } from '../InitialState/tipoPeriodos';

const tiposPeriodosReducer = (state = tipoPeriodoInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_TIPOPERIODOS':
      return {
        ...state,
        tiposPeriodos: payload,
      };
    case 'GET_TIPOPERIODOS_DROPDOWN':
      return {
        ...state,
        dropdownTiposPeriodos: payload,
      };
    case 'GET_TIPOPERIODO':
      return {
        ...state,
        tipoPeriodo: payload,
      };
    case 'POST_TIPOPERIODO':
      return {
        ...state,
        novoTipoPeriodo: payload,
      };
    case 'PUT_TIPOPERIODO':
      return {
        ...state,
        tipoPeriodoEditado: payload,
      };
    case 'DELETE_TIPOPERIODO':
      return {
        ...state,
        tipoPeriodoRemovido: payload,
      };
    case 'CLEAR_DATA_TIPOPERIODO':
      return {
        ...state,
        tipoPeriodo: [],
      };

    default:
      return state;
  }
};

export default tiposPeriodosReducer;

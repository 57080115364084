import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles({
//   active: {
//     color: '#E21450',
//     fontSize: 30,
//   },
//   sidebarLink: {
//     fontSize: 20,
//     margin: 20,
//     alignItems: 'center',
//     position: 'relative',
//     cursor: 'pointer',
//     display: 'flex',
//   },
//   sidebarLinkTitle: {
//     margin: 0,
//     fontSize: 15,
//     position: 'relative',
//     display: 'flex',
//   },
// });

const activeStyle = {
  color: '#E21450',
};

function SidebarLink({ title, icon, newLink, route, onClick }) {
  // const classes = useStyles();
  return (
    <NavLink
      to={route}
      onClick={onClick}
      style={({ isActive }) => (isActive ? activeStyle : undefined)}
    >
      <li className="sidebar__link">
        {icon}
        <p className="sidebar__link-title">
          {title}
          {newLink ? (
            <Badge className="sidebar__link-badge">
              <span>New</span>
            </Badge>
          ) : (
            ''
          )}
        </p>
      </li>
    </NavLink>
  );
}

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLink;

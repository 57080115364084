/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Box,
  Typography,
} from '@mui/material';
import AusenciaTooltip from '../Tooltip/NormalToolttip';

// Actions

// Components
import HorasButtons from '../HorasButtons';

function RoleEncarregado({
  infoValIndiv,
  tiposatrasos,
  tiposperiodos,
  classes,
  setDisabledFaltou,
  setValueTipoFaltaAutoComplete,
  width,
}) {
  // eslint-disable-next-line no-unused-vars
  const [horas, setHoras] = useState(infoValIndiv && infoValIndiv.qtd);
  // eslint-disable-next-line no-unused-vars
  const [checkbox, setCheckbox] = useState((infoValIndiv && infoValIndiv.faltou) || false);
  const [valueTipoAtrasoAutoComplete, setValueTipoAtrasoAutoComplete] = useState({
    id: 0,
    descricao: '',
  });

  const [valueTipoPeriodoAutoComplete, setValueTipoPeriodoAutoComplete] = useState({
    id: 0,
    descricao: '',
  });
  const [valueObservacoesTextField, setValueObservacoesTextField] = useState('');

  useEffect(() => {
    setHoras(infoValIndiv.qtd);
    setCheckbox(infoValIndiv.faltou);
    const tipoAtrasoSelected = tiposatrasos.find(
      (atraso) => atraso.id === infoValIndiv.tipoatrasos_id,
    );
    const tipoPeriodoSelected = tiposperiodos.find(
      (periodo) => periodo.id === infoValIndiv.tipoperiodos_id,
    );

    if (infoValIndiv.faltou) {
      setDisabledFaltou(false);
    }

    if (tipoAtrasoSelected) {
      setValueTipoAtrasoAutoComplete(tipoAtrasoSelected);
    } else {
      setValueTipoAtrasoAutoComplete(null);
    }
    if (tipoPeriodoSelected) {
      setValueTipoPeriodoAutoComplete(tipoPeriodoSelected);
    } else {
      setValueTipoPeriodoAutoComplete(null);
    }
    setValueObservacoesTextField(infoValIndiv.observacoes || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoValIndiv]);

  const handleFaltas = (falta) => {
    setCheckbox(falta);

    infoValIndiv.faltou = falta;
    setDisabledFaltou(!falta);
    if (infoValIndiv.faltou == false) {
      setValueTipoFaltaAutoComplete(null);
    }
    // dispatch(GuardarTempos(recursosAssociados));
  };

  const handleChangeAtrasos = (value) => {
    setValueTipoAtrasoAutoComplete(value);

    if (value) {
      infoValIndiv.tipoatrasos_id = value.id;
    } else {
      infoValIndiv.tipoatrasos_id = null;
    }

    // dispatch({ type: 'GET_VALIDACAO_INDIVIDUAL_RH', payload: infoValIndiv });
  };

  const handleChangePeriodo = (value) => {
    setValueTipoPeriodoAutoComplete(value);

    if (value) {
      infoValIndiv.tipoperiodos_id = value.id;
    } else {
      infoValIndiv.tipoperiodos_id = null;
    }
    // dispatch(GuardarTempos(recursosAssociados));
  };

  const handleChangeObservacoes = (e) => {
    setValueObservacoesTextField(e.target.value);

    infoValIndiv.observacoes = e.target.value;
    // dispatch(GuardarTempos(recursosAssociados));
  };

  const handleData = (currentHoras) => {
    infoValIndiv.qtd = currentHoras;
    setHoras(currentHoras);

    infoValIndiv.qtd = currentHoras;
    // dispatch(GuardarTempos(recursosAssociados));
  };

  return (
    <>
      <Box sx={{ borderBottom: '1px solid #000', my: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Encarregado de obra
        </h4>
      </Box>
      <Box sx={{ display: 'flex', my: 2, alignItems: 'center', flexWrap: 'wrap' }}>
        <Box
          sx={{
            width: width < 1300 ? '40%' : '20%',
            display: 'flex',
            alignItems: 'center',
            mb: width < 1300 ? 2 : 0,
            marginTop: '20px',
          }}
        >
          <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            {infoValIndiv.recursos_nome2 ? infoValIndiv.recursos_nome2 : infoValIndiv.recursos_nome}
          </Typography>
          {/* ------------------------- Ausencia ----------------------- */}
          {infoValIndiv && (infoValIndiv.ausencia || infoValIndiv.notificacaoHorasNoutraObra) && (
            <AusenciaTooltip recurso={infoValIndiv} />
          )}
          {/* ---------------------------------------------------------- */}
        </Box>
        <Box
          sx={{
            width: width < 1300 ? '20%' : '10%',
            mb: width < 1300 ? 2 : 0,
            height: '40px',
            marginTop: '20px',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox}
                onChange={() => {
                  // setCheckbox(!infoValIndiv.faltou);
                  handleFaltas(!checkbox);
                }}
                name="falta"
                disabled
              />
            }
            label="Falta"
            // style={{ width: '20%' }}
          />
        </Box>
        <Box
          sx={{ width: width < 1300 ? '40%' : '20%', mb: width < 1300 ? 2 : 0, marginTop: '20px' }}
        >
          <HorasButtons infoValIndiv={infoValIndiv} classes={classes} handleData={handleData} />
        </Box>
        <Box sx={{ width: width < 1300 ? '30%' : '15%', mr: '1%' }}>
          <b>Tipo de Atraso</b>
          <Autocomplete
            id="tipoAtrasoId"
            name="tipoAtrasoId"
            noOptionsText="Sem dados"
            onChange={(e, value) => handleChangeAtrasos(value)}
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            value={valueTipoAtrasoAutoComplete}
            options={tiposatrasos || [{ id: 0, descricao: '' }]}
            getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
            filterOptions={(options, state) => {
              return options.filter(
                (option) =>
                  option.id > 0 &&
                  `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
              );
            }}
            getOptionSelected={(option, value) => {
              // if (!value.id) return true

              return value.id === option.id;
            }}
            autoComplete
            style={{ width: '100%' }}
            includeInputInList
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                // required
                // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
              />
            )}
          />
        </Box>
        <Box sx={{ width: width < 1300 ? '30%' : '15%', ml: '1%' }}>
          <b>Tipo de Periodo</b>
          <Autocomplete
            id="tipoPeriodoId"
            name="tipoPeriodoId"
            noOptionsText="Sem dados"
            onChange={(e, value) => handleChangePeriodo(value)}
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            value={valueTipoPeriodoAutoComplete}
            options={tiposperiodos || [{ id: 0, descricao: '' }]}
            getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
            filterOptions={(options, state) => {
              return options.filter(
                (option) =>
                  option.id > 0 &&
                  `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
              );
            }}
            isOptionEqualToValue={(option, value) => {
              // if (!value.id) return true;

              return value.id === option.id;
            }}
            autoComplete
            style={{ width: '100%' }}
            includeInputInList
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                // required
                // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
              />
            )}
          />
        </Box>
        <Box sx={{ width: width < 1300 ? '34%' : '16%', ml: '2%' }}>
          <b>Obs. Encarregado</b>
          <TextField
            name="observacoes"
            variant="outlined"
            style={{ width: '100%' }}
            size="small"
            value={valueObservacoesTextField || ''}
            onChange={handleChangeObservacoes}
            disabled
          />
        </Box>
      </Box>
    </>
  );
}

export default RoleEncarregado;

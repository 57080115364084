import React, { useEffect } from 'react';
// import '../parceiros.css';

// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Modal, Backdrop, Fade, Button, TextField, CircularProgress, Box } from '@mui/material';
import { grey } from '@mui/material/colors';

// ACTIONS
import EditarTipoAtraso from '../../../../../redux/actions/TiposAtrasosAction/editarTipoAtrasoAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

function ModalEdit({
  openEdit,
  handleClose,
  tipoAtraso,
  setTipoAtraso,
  validateOnChange = true,
  validationTipoAtraso,
  errorsTipoAtraso = null,
  fetchTiposAtrasos,
  tipoAtrasoInfo,
  tipoAtrasoId,
  tipoAtrasoNome,
  show,
  // Loading
  loading,
  setLoading,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();

    setTipoAtraso({
      ...tipoAtraso,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationTipoAtraso({ [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setTipoAtraso({
      descricao: tipoAtrasoInfo.descricao,
    });
  }, [tipoAtrasoInfo, setTipoAtraso]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationTipoAtraso()) {
      setLoading(true);
      const ydata = [
        {
          descricao: tipoAtraso.descricao,
        },
      ];

      dispatch(EditarTipoAtraso(ydata, tipoAtrasoId, show))
        .then(() => handleClose('edit', e))
        .then(() => fetchTiposAtrasos());
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openEdit}
      onClose={(e) => handleClose('edit', e)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEdit}>
        <Box sx={style}>
          <div className="header-modal">
            <h3 id="transition-modal-title" className="titulo-modal">
              Editar Tipo de Atraso
            </h3>
            <p id="transition-modal-description" className="descricao-modal">
              Pode editar o tipo de atraso <b> {tipoAtrasoNome}</b>.
            </p>
          </div>

          <div>
            <b>Descrição</b>
            <TextField
              id="Descricao"
              name="descricao"
              size="small"
              // variant='outlined'
              // color='primary'
              value={tipoAtraso.descricao || ''}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              className="nome"
              // style={{ marginBottom: 8, marginRight: '4%' }}
              {...(errorsTipoAtraso.descricao && {
                error: true,
                helperText: errorsTipoAtraso.descricao,
              })}
            />
          </div>

          <br />
          <br />
          <div className={classes.modalBtns}>
            <Button
              className={classes.btnCancelar}
              variant="contained"
              onClick={(e) => handleClose('edit', e)}
            >
              Cancelar
            </Button>
            <Button
              className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
              disabled={!!loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading && (
                <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
              )}
              Confirmar
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalEdit;

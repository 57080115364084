import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';

import Router from './Router';

import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';

// Token from Redux

i18n.init(i18nextConfig);

// const ThemeComponent = ({ children, themeName }) => {
//   const theme = createTheme({
//     palette: {
//       type: themeName === 'theme-dark' ? 'dark' : 'light',
//     },
//   });

//   return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
// };

// ThemeComponent.propTypes = {
//   children: PropTypes.shape().isRequired,
//   themeName: PropTypes.string.isRequired,
// };

// const ConnectedThemeComponent = connect((state) => ({
//   themeName: state.theme.className,
// }))(ThemeComponent);

function App() {
  useEffect(() => {
    // firebase.initializeApp(firebaseConfig);
  }, []);

  return (
    <BrowserRouter basename="/">
      <I18nextProvider i18n={i18n}>
        <ScrollToTop>
          {/* <ConnectedThemeComponent> */}
          <Router />
          {/* </ConnectedThemeComponent> */}
        </ScrollToTop>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;

/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import '../parceiros.css';

// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Modal, Backdrop, Fade, Button, TextField, CircularProgress, Box } from '@mui/material';
import { Col, Row, Container } from 'reactstrap';
import { orange, grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptLocale from 'date-fns/locale/pt';

// ACTIONS

import EditarFuncionarioRegistoHoras from '../../../../../../../redux/actions/FuncionariosActions/horas/editarFuncionarioRegistoHorasAction';

// COMPONENTS
import HorasButtons from './components/HorasButtons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  // Component HorasButtons
  buttons: {
    // padding: '0px 0px !important',
    color: '#E21450',
    minWidth: '45px',
    height: '40px',
    marginRight: '8px !important',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  buttonsUltimo: {
    // padding: '0px 0px !important',

    color: 'white',
    backgroundColor: orange[600],
    '&:hover': {
      backgroundColor: orange[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: orange[600],
      },
    },
    minWidth: '50px',
    height: '40px',
  },
  horasBorderPeriodoTextfield: {
    width: '50px',
    height: '40px',
    textAlign: 'center',
    border: '1px solid #000',
    backgroundColor: '#ebebeb',
    borderRadius: '5px',
    padding: '8px',
  },
}));

function ModalEdit({
  openEdit,
  handleClose,
  show,
  formHoras,
  setFormHoras,
  registoHorasId,
  fetchHorasFuncionario,
  // Loading
  loading,
  setLoading,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  // const handleChangeFerias = (e) => {
  //   setFormFerias({ ...formFerias, [e.target.name]: e.target.value });
  //   if (validateOnChange) validationFerias({ [e.target.name]: e.target.value });
  // };

  const handleRegistoHoras = (registoHoras) => {
    setFormHoras({ ...formHoras, registoHoras });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const ydata = [
      {
        horas: formHoras.registoHoras,
      },
    ];

    console.log(ydata);

    dispatch(EditarFuncionarioRegistoHoras(ydata, registoHorasId, show))
      .then(() => handleClose('edit', e))
      .then(() => fetchHorasFuncionario());
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openEdit}
      onClose={(e) => handleClose('edit', e)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEdit}>
        <Box sx={style}>
          <div className="header-modal">
            <h3 id="transition-modal-title" className="text-underline-title-dark">
              Editar Registo de Horas
            </h3>
            <p id="transition-modal-description">
              Pode editar aqui o registo de horas do funcionário.
            </p>
          </div>

          {/* <div style={{ margin: '0px 20px' }}>
                <span style={{ fontWeight: 'bold' }}>Obra Id: </span>
                {formHoras && formHoras.obra}
              </div>
              <div style={{ margin: '0px 20px' }}>
                <span style={{ fontWeight: 'bold' }}>Data: </span>
                {formHoras &&
                  formHoras.data != '' &&
                  format(new Date(formHoras.data), 'dd/MM/yyyy')}
              </div> */}
          <Box sx={{ mb: 2 }}>
            <b>Obra</b>
            <TextField
              id="obra"
              name="obra"
              size="small"
              color="primary"
              value={formHoras && formHoras.obra}
              fullWidth
              autoComplete="off"
              disabled
              style={{ width: '100%' }}
            />
          </Box>
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
              <b>Data Inicio</b>
              <DatePicker
                style={{ width: '100%', marginTop: 0 }}
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                okLabel="ok"
                cancelLabel="cancelar"
                invalidDateMessage="Data Inválida"
                value={formHoras && formHoras.data}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'dd/mm/aaaa',
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    variant="outlined"
                    size="small"
                  />
                )}
                disabled
              />
            </LocalizationProvider>
          </Box>
          <Container style={{ padding: '0' }}>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                <div className="horas">
                  <div className={classes.horasBorderPeriodoTextfield}>
                    <h4>{formHoras && formHoras.registoHoras}h</h4>
                  </div>
                  <div>
                    <HorasButtons
                      formHoras={formHoras}
                      classes={classes}
                      handleData={handleRegistoHoras}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <br />
          <br />
          <div className={classes.modalBtns}>
            <Button
              className={classes.btnCancelar}
              variant="contained"
              onClick={(e) => handleClose('edit', e)}
            >
              Cancelar
            </Button>
            <Button
              className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
              disabled={!!loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading && (
                <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
              )}
              Confirmar
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalEdit;

/* eslint-disable quotes */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

//  PROVIDER E STORE
import { Provider } from 'react-redux';
//  Persisted state
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import theme from './shared/MuiTheme/theme';
import { store, persistor } from './containers/App/store';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();

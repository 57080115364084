/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Col, Container, Row, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import CardRecurso from './components/CardRecurso';
// import FetchObras from '../../../../../../../AssociarRecursos/components/FetchObras';
// Breadcrumbs
import BreadCriarEquipas from '../../../../App/Breadcrumbs/CriarEquipas/BreadCriarEquipas';

import FooterLinks from '../../../../Account/Footer';
// TESTE
import TodosOsRecursos from '../../../../../redux/actions/RecursosActions/todosOsRecursosAction';
import EncarregadosRecursos from '../../../../../redux/actions/RecursosActions/encarregadosAction';
import FuncionariosRecursos from '../../../../../redux/actions/RecursosActions/funcionariosAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  btnMudarCor: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnGuardar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnGuardarLoading: {
    color: 'white',
    backgroundColor: grey[300],
    width: '120px',
    height: '40px',
  },

  btnVoltar: {
    height: '30px',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
}));

function Equipas() {
  const classes = useStyles();

  // const reactTableData = FetchObras();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchRecursos = () => {
    const id = location.pathname.split('/')[3];
    dispatch(TodosOsRecursos(id, navigate));
  };
  const fetchEncarregados = () => {
    dispatch(EncarregadosRecursos());
  };
  const fetchFuncionarios = () => {
    dispatch(FuncionariosRecursos());
  };
  useEffect(() => {
    fetchEncarregados();
  }, []);
  useEffect(() => {
    fetchFuncionarios();
  }, []);
  useEffect(() => {
    fetchRecursos();
  }, []);
  useEffect(() => {
    dispatch({ type: 'GET_RECURSOS', payload: {} });
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadCriarEquipas />
            {/* <h3 className="page-title">{t("tables.resizable_table.title")}</h3> */}
            <h3 className="page-title" style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to="/app/criarEquipas" style={{ textDecoration: 'none' }}>
                <ButtonToolbar
                  className="btn-toolbar--center"
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <Button id="TooltipBottom" className={classes.btnVoltar} color="secondary">
                    <span
                      className="lnr lnr-chevron-left"
                      style={{ fontSize: 10, fontWeight: 900 }}
                    />
                  </Button>

                  <UncontrolledTooltip
                    // dir={dir}
                    placement="bottom"
                    target="TooltipBottom"
                  >
                    Voltar
                  </UncontrolledTooltip>
                </ButtonToolbar>
              </Link>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                }}
                className="text-underline"
              >
                Criar Equipa
              </h4>
            </h3>
          </Col>
        </Row>
        <Col md={12} sm={12}>
          <Row>
            <CardRecurso fetchRecursos={fetchRecursos} classes={classes} />
          </Row>
        </Col>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
}

export default Equipas;

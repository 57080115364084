/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import '../parceiros.css';

// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
  Box,
} from '@mui/material';
import { Col, Row, Container } from 'reactstrap';
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';
import DatePickerAusencias from './datePicker/datePickerAusencias';

// ACTIONS

import EditarAusencia from '../../../../../../../redux/actions/FuncionariosActions/ausencias/editarAusenciaAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

function ModalEdit({
  openEdit,
  handleClose,
  ausencia,
  setAusencia,
  validateOnChange = true,
  validationAusencia,
  errorsAusencia = null,
  // tiposfaltas,
  funcId,
  show,
  fetchFuncionarioAusencias,
  ausenciaInfo,
  faltaSelecionada,
  setFaltaSelecionada,
  // Loading
  loading,
  setLoading,
  tiposFaltasDrop,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [formatedDataInicio, setFormatedDataInicio] = useState();
  const [formatedDataFim, setFormatedDataFim] = useState();

  const handleChangeFalta = (e, value) => {
    setAusencia({ ...ausencia, tipoFaltaId: value });
    setFaltaSelecionada({
      id: 0,
      descricao: '',
    });
    // eslint-disable-next-line no-param-reassign
    ausenciaInfo.tiposfaltas_id = value && value.id;

    if (validateOnChange) validationAusencia({ tipoFaltaId: value });
  };
  const handleChangeObservacoes = (e) => {
    setAusencia({ ...ausencia, observacoes: e.target.value });
  };

  const selectFaltaId = () => {
    let faltaAssociada = {};
    if (tiposFaltasDrop.length > 0 && ausenciaInfo) {
      faltaAssociada = tiposFaltasDrop.find((falta) => falta.id === ausenciaInfo.tiposfaltas_id);

      // setFaltaSelecionada(
      //   faltaAssociada || {
      //     id: 0,
      //     descricao: '',
      //     observacoes: ausenciaInfo.observacoes,
      //   },
      // );
      setAusencia({
        ...ausencia,
        tipoFaltaId: faltaAssociada,
        observacoes: ausenciaInfo.observacoes,
      });
    } else {
      setFaltaSelecionada({
        id: 0,
        descricao: '',
        observacoes: ausenciaInfo.observacoes,
      });
    }
  };

  useEffect(() => {
    selectFaltaId();
  }, [ausenciaInfo]);

  useEffect(() => {
    if (ausenciaInfo) {
      setFormatedDataInicio(ausenciaInfo.datainicio);
      setFormatedDataFim(ausenciaInfo.datafim);
    }
  }, [ausenciaInfo]);

  const handleDataInicioAusenciaChange = (date) => {
    setFormatedDataInicio(date);
    setFormatedDataFim(date);
    if (validateOnChange) validationAusencia({ dataInicio: date, dataFim: date });
  };

  const handleDataFimAusenciaChange = (date) => {
    setFormatedDataFim(date);
    if (validateOnChange) validationAusencia({ dataInicio: formatedDataInicio, dataFim: date });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const fDataInicio = format(new Date(formatedDataInicio), 'yyyy-MM-dd');
    const fDataFim = format(new Date(formatedDataFim), 'yyyy-MM-dd');

    if (validationAusencia()) {
      setLoading(true);
      const ydata = [
        {
          funcionarioId: funcId,
          datainicio: fDataInicio,
          datafim: fDataFim,
          tiposFaltasId: ausencia.tipoFaltaId.id,
          observacoes: ausencia.observacoes,
        },
      ];

      dispatch(EditarAusencia(ydata, funcId, ausenciaInfo && ausenciaInfo.id, show))
        .then(() => handleClose('edit', e))
        .then(() => fetchFuncionarioAusencias());
      //   .then(() => handleOpenAlert('edit'));

      console.log(ydata);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openEdit}
      onClose={(e) => handleClose('edit', e)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEdit}>
        <Box sx={style}>
          <div className="header-modal">
            <h3 id="transition-modal-title" className="text-underline-title-dark">
              Editar Falta
            </h3>
            <p id="transition-modal-description">Adicione a falta e o periodo respectivo</p>
          </div>

          <Container style={{ padding: '0' }}>
            <Row>
              <Col md={12} sm={12}>
                <Box sx={{ mb: 2 }}>
                  <b>Tipo de Falta</b>
                  <Autocomplete
                    id="tipoFaltaId"
                    name="tipoFaltaId"
                    noOptionsText="Sem dados"
                    onChange={(e, value) => handleChangeFalta(e, value)}
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    // disabled={disabledFaltou || infoValIndiv.faltou == 0}
                    value={ausencia && ausencia.tipoFaltaId}
                    options={tiposFaltasDrop || [{ id: 0, descricao: '' }]}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    getOptionDisabled={(option) => option.visibilidade == 2}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option.id > 0 &&
                          `${option.descricao}`
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    autoComplete
                    style={{ width: '100%' }}
                    includeInputInList
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // variant="outlined"
                        size="small"
                        // required
                        {...(errorsAusencia.tipoFaltaId && {
                          error: true,
                          helperText: errorsAusencia.tipoFaltaId,
                        })}
                      />
                    )}
                  />
                </Box>
              </Col>
            </Row>
            <DatePickerAusencias
              handleDataInicioAusenciaChange={handleDataInicioAusenciaChange}
              handleDataFimAusenciaChange={handleDataFimAusenciaChange}
              formatedDataInicio={formatedDataInicio}
              formatedDataFim={formatedDataFim}
              faltaSelecionada={faltaSelecionada}
              errorsAusencia={errorsAusencia}
            />
            <b>Observações</b>
            <TextField
              id="observacoes"
              name="observacoes"
              size="small"
              color="primary"
              value={ausencia && ausencia.observacoes}
              onChange={handleChangeObservacoes}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 8 }}
            />
          </Container>
          <br />
          <br />
          <div className={classes.modalBtns}>
            <Button
              className={classes.btnCancelar}
              variant="contained"
              onClick={(e) => handleClose('edit', e)}
            >
              Cancelar
            </Button>
            <Button
              className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
              disabled={!!loading || !ausencia.tipoFaltaId}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading && (
                <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
              )}
              Confirmar
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalEdit;
